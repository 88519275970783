import { backofficeClientStore } from 'services/backofficeIntegration/http/client/backofficeClientStore';

export const logInfo = (message: string) => {
  return backofficeClientStore.getState().client?.post(
    '/logs',
    btoa(
      JSON.stringify({
        message,
        severity: 'INFO',
        stack_trace: JSON.stringify([]),
        context: {
          timestamp: new Date().toISOString()
        }
      })
    )
  );
};

// @ts-expect-error - Expose logInfo to the window object for debugging purposes
window.logInfo = logInfo;
