import { LoadingAnimationWithTip } from 'features/loading-spinner/LoadingAnimationWithTip';
import styled from 'styled-components';

export const StepInProgressState = () => (
  <Root>
    <LoadingAnimationWithTip />
  </Root>
);

const Root = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
`;
