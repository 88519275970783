import { TextInputField } from 'features/workflowBlogPostBuilder/steps/components/TextInputField';
import { FormattedMessage } from 'react-intl';
import useTr, { TranslationFunction } from 'utils/hooks/useTr';

import * as CommonStyled from './common.styles';

const outlineValidator = (value: string, translate: TranslationFunction) => {
  const lines = value.split('\n');
  if (lines.length > 10) {
    return translate('blog_post_builder.steps.outline.error');
  }

  return undefined;
};

export const SelectOutlineStep = () => {
  const translate = useTr();

  return (
    <>
      <CommonStyled.Title>
        <FormattedMessage id="blog_post_builder.steps.outline.title" />
      </CommonStyled.Title>

      <TextInputField
        name="outline"
        placeholder={translate('blog_post_builder.steps.outline.placeholder')}
        validate={(value: string) => outlineValidator(value, translate)}
      />
    </>
  );
};
