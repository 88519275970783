import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { SeoSearchResult } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/SeoSearchResult/SeoSearchResult';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { getSeoAnalysisResults } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

const MainSubStepSearchResultSection = () => {
  const dispatch = useAppDispatch();
  const tr = useTr();

  const seoAnalysisResults = useAppSelector(getSeoAnalysisResults);

  if (!seoAnalysisResults) {
    return null;
  }

  return (
    <FlexContainer gap="medium">
      <FlexContainer gap="xxsmall">
        <Typography variant="h6">
          <FormattedMessage id="aiWriter.inspirations.seoAnalysis.search_results.title" />
        </Typography>
        <Typography variant="caption">
          <FormattedMessage
            id="aiWriter.inspirations.seoAnalysis.search_results.subtitle"
            values={{
              a: (text: string) => (
                <a
                  href={tr('aiWriter.inspirations.seoAnalysis.search_results.subtitle.link')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text}
                </a>
              )
            }}
          />
        </Typography>
      </FlexContainer>
      {seoAnalysisResults.slice(0, 5).map(result => (
        <SeoSearchResult key={result.link} result={result} />
      ))}
      <Button
        id={gtmIds.aiWriter.seo.analysisSERP}
        endIcon={<ArrowForwardIcon color="primary" />}
        onClick={() =>
          dispatch(
            setInspirationsCurrentStep({
              step: AiWriterSidebarStep.performance,
              subStep: 'seoSearchResults'
            })
          )
        }
      >
        <FormattedMessage id="aiWriter.inspirations.seoAnalysis.all_results" />
      </Button>
    </FlexContainer>
  );
};

export default MainSubStepSearchResultSection;
