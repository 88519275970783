import {
  AddCircleRounded,
  AdsClickRounded,
  ArticleRounded,
  DraftsRounded,
  LightbulbRounded,
  LinkedIn,
  NewspaperRounded,
  PlaylistAddCheckRounded,
  StickyNote2Outlined,
  ThumbUpAltRounded
} from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { ReactComponent as AiImagesIcon } from 'assets/icons/icon-home-aiImages-tile.svg';
import { ReactComponent as RewriteIcon } from 'assets/icons/new-icon-rewrite.svg';
import { getRoutePath } from 'config/routes';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { isDevEnv } from 'utils/isDevEnv';

export type QuickActionCardProps = {
  name: string;
  title: ReactNode;
  subtitle: ReactNode;
  gtmId?: string;
  onClick: () => void;
  icon?: ReactNode;
};

export const QuickActionSteps = {
  blankDocument: 'blankDocument',
  socialMediaPost: 'socialMediaPost',
  placeholder: 'placeholder',
  blogOrArticle: 'blogOrArticle',
  AiImage: 'AiImage',
  linkedInPost: 'linkedInPost',
  contentIdeas: 'contentIdeas',
  marketingCampaign: 'marketingCampaign',
  shortBlogPost: 'shortBlogPost',
  rewriteArticles: 'rewriteArticles',
  productLandingPage: 'productLandingPage',
  newsletter: 'newsletter',
  summarizeURL: 'summarizeURL'
};

const workflowIds: Record<string, number> = {
  contentIdeas: 50,
  linkedInPost: isDevEnv() ? 141 : 62,
  // TODO: actual ID is missing
  productLandingPage: 51,
  marketingCampaign: 48,
  rewriteArticles: 49,
  newsletter: 60,
  shortBlogPost: 55,
  summarizeURL: 52
};

export const useQuickActions = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNewDocumentClick = async () => {
    await navigate(getRoutePath('aiWriter'));
    trackingWrapper.track('aiWriterOverviewNewDocumentStarted');

    navigate(getRoutePath('newDocument'));
  };

  const handleBlogPostBuilderClick = async () => {
    trackingWrapper.track('aiWriterOverviewBlogPostBuilderStarted');

    navigate(getRoutePath('blogPostWorkflow'));
  };

  const handleSocialMediaBuilderClick = async () => {
    trackingWrapper.track('aiWriterOverviewSocialMediaBuilderStarted');

    navigate(getRoutePath('socialPostWorkflow'));
    dispatch(setInspirationsCurrentStep(undefined));
  };

  const handleImageFlashClick = () => {
    navigate(getRoutePath('aiImages'));
  };

  const handleWorkflowClick = (workflowName: string) => {
    const workflowId = workflowIds[workflowName];
    navigate(getRoutePath('modularWorkflowRun', { workflowId }));
  };

  const quickActions: QuickActionCardProps[][] = [
    [
      {
        name: QuickActionSteps.blankDocument,
        title: <FormattedMessage id="common.new_document" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.blank_document_description" />,
        onClick: handleNewDocumentClick,
        gtmId: gtmIds.aiWriter.createNewProject,
        icon: (
          <QuickCreateAvatar>
            <AddCircleIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.socialMediaPost,
        title: <FormattedMessage id="aiWriter.project_overview.social_post_builder.title" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.social_post_builder.subtitle" />,
        onClick: handleSocialMediaBuilderClick,
        gtmId: gtmIds.aiWriter.newSocialPostBuilder,
        icon: (
          <QuickCreateAvatar>
            <ThumbUpIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.linkedInPost,
        title: <FormattedMessage id="aiWriter.project_overview.linkedin_post" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.linkedin_post_description" />,
        onClick: () => handleWorkflowClick(QuickActionSteps.linkedInPost),
        gtmId: gtmIds.aiWriter.newLinkedInPost,
        icon: (
          <QuickCreateAvatar>
            <LinkedinIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.blogOrArticle,
        title: <FormattedMessage id="aiWriter.project_overview.blog_post_builder" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.blog_post_builder_description" />,
        onClick: handleBlogPostBuilderClick,
        gtmId: gtmIds.aiWriter.newBlogPostBuilder,
        icon: (
          <QuickCreateAvatar>
            <ArticleIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.AiImage,
        title: <FormattedMessage id="aiWriter.project_overview.ai_images" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.ai_images_description" />,
        onClick: handleImageFlashClick,
        gtmId: gtmIds.aiWriter.newAIImages,
        icon: (
          <QuickCreateAvatar>
            <AiImagesIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.contentIdeas,
        title: <FormattedMessage id="aiWriter.project_overview.30_day_content" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.30_day_content_description" />,
        onClick: () => handleWorkflowClick(QuickActionSteps.contentIdeas),
        gtmId: gtmIds.aiWriter.newContentIdeas,
        icon: (
          <QuickCreateAvatar>
            <LightbulbIcon />
          </QuickCreateAvatar>
        )
      }
    ],
    [
      {
        name: QuickActionSteps.productLandingPage,
        title: <FormattedMessage id="aiWriter.project_overview.product_landing_page" />,
        subtitle: (
          <FormattedMessage id="aiWriter.project_overview.product_landing_page_description" />
        ),
        onClick: () => handleWorkflowClick(QuickActionSteps.productLandingPage),
        gtmId: gtmIds.aiWriter.newProductLandingPage,
        icon: (
          <QuickCreateAvatar>
            <ProductLandingPageIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.marketingCampaign,
        title: <FormattedMessage id="aiWriter.project_overview.marketing_campaign" />,
        subtitle: (
          <FormattedMessage id="aiWriter.project_overview.marketing_campaign_description" />
        ),
        onClick: () => handleWorkflowClick(QuickActionSteps.marketingCampaign),
        gtmId: gtmIds.aiWriter.newMarketingCampaign,
        icon: (
          <QuickCreateAvatar>
            <MarketingCampaignIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.rewriteArticles,
        title: <FormattedMessage id="aiWriter.project_overview.rewrite_articles" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.rewrite_articles_description" />,
        onClick: () => handleWorkflowClick(QuickActionSteps.rewriteArticles),
        gtmId: gtmIds.aiWriter.newRewriteArticles,
        icon: (
          <QuickCreateAvatar>
            <RewriteArticlesIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.newsletter,
        title: <FormattedMessage id="aiWriter.project_overview.newsletter" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.newsletter_description" />,
        onClick: () => handleWorkflowClick(QuickActionSteps.newsletter),
        gtmId: gtmIds.aiWriter.newNewsletter,
        icon: (
          <QuickCreateAvatar>
            <NewsletterIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.shortBlogPost,
        title: <FormattedMessage id="aiWriter.project_overview.short_blog_post" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.short_blog_post_description" />,
        onClick: () => handleWorkflowClick(QuickActionSteps.shortBlogPost),
        gtmId: gtmIds.aiWriter.newShortBlogPost,
        icon: (
          <QuickCreateAvatar>
            <ShortArticleIcon />
          </QuickCreateAvatar>
        )
      },
      {
        name: QuickActionSteps.summarizeURL,
        title: <FormattedMessage id="aiWriter.project_overview.summarize_url" />,
        subtitle: <FormattedMessage id="aiWriter.project_overview.summarize_url_description" />,
        onClick: () => handleWorkflowClick(QuickActionSteps.summarizeURL),
        gtmId: gtmIds.aiWriter.newSummarizeURL,
        icon: (
          <QuickCreateAvatar>
            <SummarizeUrlIcon />
          </QuickCreateAvatar>
        )
      }
    ]
  ];

  return quickActions;
};

const ArticleIcon = styled(ArticleRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const ThumbUpIcon = styled(ThumbUpAltRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const AddCircleIcon = styled(AddCircleRounded)`
  width: 35px;
  height: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const LightbulbIcon = styled(LightbulbRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const ProductLandingPageIcon = styled(NewspaperRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const MarketingCampaignIcon = styled(AdsClickRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const RewriteArticlesIcon = styled(RewriteIcon)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const NewsletterIcon = styled(DraftsRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const ShortArticleIcon = styled(StickyNote2Outlined)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const SummarizeUrlIcon = styled(PlaylistAddCheckRounded)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const LinkedinIcon = styled(LinkedIn)`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const QuickCreateAvatar = styled(Avatar)`
  && {
    background-color: ${({ theme }) => theme.colors.primaryColorMain};
    width: 56px;
    height: 56px;
  }
`;
