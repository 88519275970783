import NoTabsPlaceholder from 'components/TabbedCard/NoTabsPlaceholder';
import { ErrorBoundary } from 'features/react-error-boundary/ErrorBoundary';
import React, { useMemo } from 'react';
import styled from 'styled-components';

type Props = {
  activeTab: string | null;
  className?: string;
  noTabsMessage: string;
  customContentContainer?: boolean;
  children: React.ReactNode;
};

const TabbedCard = ({
  activeTab,
  className,
  noTabsMessage,
  customContentContainer,
  children
}: Props) => {
  const content = useMemo(() => {
    const body = (
      <ErrorBoundary>
        {activeTab ? children : <NoTabsPlaceholder message={noTabsMessage} />}
      </ErrorBoundary>
    );

    return customContentContainer ? body : <Content>{body}</Content>;
  }, [customContentContainer, activeTab, noTabsMessage, children]);

  return <Root className={className}>{content}</Root>;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  flex: 1 1 auto;

  @media (min-width: 576px) {
    padding: 1rem;
  }

  @media (min-width: 992px) {
    padding: 1.5rem;
  }
`;

export default TabbedCard;
