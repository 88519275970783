import './index.scss';

import SectionWithActions from 'components/base/SectionWithActions';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import TabbedCard from 'components/TabbedCard/TabbedCard';
import LocalStorageKey from 'config/localStorageKey';
import {
  getEmbeddingModelMapperVariablesSelector,
  getEmbeddingModelsByCountry
} from 'features/embeddingModels/store/selectors';
import BubbleChart from 'features/explorer/BubbleChart';
import GraphSettingsDropdown from 'features/explorer/BubbleChart/GraphSettingsDropdown';
import ConfigSection from 'features/explorer/ConfigSection';
import EmptyStatePlaceholder from 'features/explorer/EmptyStatePlaceholder';
import {
  getCurrentTabId,
  getExplorerIsLoading,
  getExplorerPageLayout,
  getIsTabInitialized,
  getTabHasData
} from 'features/explorer/store/selectors';
import { setTabModel } from 'features/explorer/store/slice';
import TabActions from 'features/explorer/TabActions';
import useCXQuery from 'features/explorer/useCXQuery';
import WordsTable from 'features/explorer/WordsTable';
import { getGetMappersForCountry } from 'features/group/store/selectors';
import { modelMapperFromWeMapper } from 'features/group/store/utils';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';

const VerticalTabContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HorizontalTabContent = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: fit-content(30%) auto;

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
`;

const useSetSwizzModelByDefault = () => {
  const dispatch = useAppDispatch();

  const modelsByCountry = useAppSelector(getEmbeddingModelsByCountry);
  const getMappersForCountry = useAppSelector(getGetMappersForCountry);
  const getMapperVariables = useAppSelector(getEmbeddingModelMapperVariablesSelector);

  // We're using the first swizz model because our main customer using the explorer is using this model
  const firstSwizzEmbeddingModel = useMemo(
    () => modelsByCountry.find(model => model.country === 'ch' && model.language === 'de'),
    [modelsByCountry]
  );

  const countryMappers = useMemo(
    () =>
      getMappersForCountry({
        country: firstSwizzEmbeddingModel?.country,
        language: firstSwizzEmbeddingModel?.language
      }).map(modelMapperFromWeMapper),
    [firstSwizzEmbeddingModel?.country, firstSwizzEmbeddingModel?.language, getMappersForCountry]
  );

  useEffect(() => {
    const firstCountryMapper = countryMappers?.[0] ?? null;
    if (!firstSwizzEmbeddingModel || !firstCountryMapper) {
      return;
    }

    const modelId = firstSwizzEmbeddingModel.id;
    const mapperName = firstCountryMapper.name;

    const mapperVariables = getMapperVariables(modelId, mapperName);

    dispatch(
      setTabModel({
        embeddingModelId: modelId,
        modelMapperId: mapperName,
        hasValence: mapperVariables.includes('valence')
      })
    );
    localStorage.setItem(LocalStorageKey.ExplorerModel, modelId);
    localStorage.setItem(LocalStorageKey.ExplorerModelMapper, mapperName);
  }, [countryMappers, dispatch, firstSwizzEmbeddingModel, getMapperVariables]);
};

export const ExplorerProjectPage = () => {
  const { showModal } = useModal();
  const isLoading = useAppSelector(getExplorerIsLoading);
  const isVerticalLayout = useAppSelector(getExplorerPageLayout);
  const currentTabId = useAppSelector(getCurrentTabId);
  const isInitialized = useAppSelector(getIsTabInitialized);
  const hasData = useAppSelector(getTabHasData);

  const [guideShown, setGuideShown] = useLocalStorage(LocalStorageKey.ExplorerGuideShown, false);

  const ContentContainer = isVerticalLayout ? VerticalTabContent : HorizontalTabContent;

  useEnableSidebarOnMount();

  // This hooks prevents the user from seeing the modal forcing them to select an embedding model
  useSetSwizzModelByDefault();

  useEffect(() => {
    if (!guideShown) {
      showModal('VIDEO_GUIDE');
      setGuideShown(true);
    }
  }, [showModal, guideShown, setGuideShown]);

  useCXQuery();

  return (
    <div className="h-100">
      <TabbedCard className="h-100" activeTab={currentTabId} noTabsMessage="exploring.no_tabs">
        <FlexContainer direction="row">
          <ConfigSection />

          <TabActions />
        </FlexContainer>

        <ContentContainer>
          {isInitialized ? (
            hasData ? (
              <>
                <SectionWithActions
                  isLoading={isLoading}
                  withLoader
                  title="exploring.results"
                  helpTooltip="exploring.results.tooltip"
                >
                  <WordsTable />
                </SectionWithActions>

                <SectionWithActions
                  isLoading={isLoading}
                  title="exploring.graph"
                  helpTooltip="exploring.graph.tooltip"
                  actions={<GraphSettingsDropdown />}
                >
                  <BubbleChart />
                </SectionWithActions>
              </>
            ) : (
              <EmptyStatePlaceholder animationOrder={3} isLoading={isLoading} />
            )
          ) : null}
        </ContentContainer>
      </TabbedCard>
    </div>
  );
};
