import { Typography } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { LoadingAnimationWithTip } from 'features/loading-spinner/LoadingAnimationWithTip';
import * as CommonStyled from 'features/workflowBlogPostBuilder/steps/common.styles';
import { useState } from 'react';
import styled from 'styled-components';
import { useTimeout } from 'utils/hooks/useTimeout';

// Show a hint after 45 seconds
const longWaitingTime = 45_000;

type Props = {
  isFinished: boolean;
};

export const GenerateContentStep = ({ isFinished }: Props) => {
  const [showLongWaitingTimeHint, setShowLongWaitingTimeHint] = useState(false);

  useTimeout(() => setShowLongWaitingTimeHint(true), longWaitingTime);

  return (
    <>
      <CommonStyled.Title>
        <FormattedMessage id="blog_post_builder.steps.generate_content.title" />
      </CommonStyled.Title>

      <StepContent isFinished={isFinished} isLongWaiting={showLongWaitingTimeHint} />
    </>
  );
};

type StepContentProps = {
  isFinished: boolean;
  isLongWaiting: boolean;
};

function StepContent({ isFinished, isLongWaiting }: StepContentProps) {
  if (isFinished) {
    return (
      <LimitedSpaceContainer>
        <Typography>
          <FormattedMessage id="blog_post_builder.steps.generate_content.content" />
        </Typography>
      </LimitedSpaceContainer>
    );
  }

  return (
    <LimitedSpaceContainer>
      <LoadingAnimationWithTip
        alternatingMessages={[
          'blog_post_builder.steps.outline.loader.average_time',
          'blog_post_builder.steps.outline.loader.creativity_progress'
        ]}
      />

      {isLongWaiting && (
        <LongWaitingTimeHintRoot>
          <Typography>
            <FormattedMessage id="blog_post_builder.steps.generate_content.long_waiting_time_hint" />
          </Typography>
        </LongWaitingTimeHintRoot>
      )}
    </LimitedSpaceContainer>
  );
}

const LimitedSpaceContainer = styled.div`
  // This improves the positioning of the loader because it’s centered in the whole area,
  // but the headline is left-aligned.
  // This doesn't makes it as beautiful as f*ck but aligns it a lil bit more
  width: 350px;
  margin: 0 auto;
`;

const LongWaitingTimeHintRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 20rem;
  padding: ${({ theme }) => `${theme.spacings.xsmall} ${theme.spacings.small}`};
  margin-top: ${({ theme }) => theme.spacings.small};

  background-color: ${({ theme }) => theme.colors.primary50};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;
