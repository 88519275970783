import { logReactError } from 'features/logging/logReactError';
import { ErrorFallback } from 'features/react-error-boundary/ErrorFallback';
import { isAutomaticPageTranslationActive } from 'features/react-error-boundary/utils/isAutomaticPageTranslationActive';
import React from 'react';

const GeneralErrorFallback = () => <ErrorFallback message="error_boundary.error.general" />;
const TranslationErrorFallback = () => (
  <ErrorFallback message="error_boundary.error.automatic_page_translation" />
);

type Props = {
  children?: React.ReactNode | (() => React.ReactNode);
};

type State =
  | {
      componentStack: null;
      error: null;
    }
  | {
      componentStack: React.ErrorInfo['componentStack'];
      error: unknown;
    };

const initialState: State = {
  componentStack: null,
  error: null
};

export class ErrorBoundary extends React.Component<Props, State> {
  public state: State;

  public constructor(props: Props) {
    super(props);

    this.state = initialState;
  }

  public componentDidCatch(error: unknown, errorInfo: React.ErrorInfo): void {
    const { componentStack } = errorInfo;

    logReactError(error, errorInfo);

    // componentDidCatch is used over getDerivedStateFromError
    // so that componentStack is accessible through state.
    this.setState({ error, componentStack });
  }

  public render(): React.ReactNode {
    const { children } = this.props;
    const state = this.state;

    if (state.error) {
      return isAutomaticPageTranslationActive() ? (
        <TranslationErrorFallback />
      ) : (
        <GeneralErrorFallback />
      );
    }

    if (typeof children === 'function') {
      return (children as () => React.ReactNode)();
    }

    return children;
  }
}
