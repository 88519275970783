import ModelSelect from 'features/embeddingModels/ModelSelect';
import { ConfigFormFields } from 'features/explorer/store/types';
import { ErrorMessage, Field, FieldProps, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { red } from 'styles/styleUtils';

const Feedback = styled.span`
  color: ${red};
`;

const ModelSelectStep = () => {
  const { initialValues, setFieldValue } = useFormikContext<ConfigFormFields>();

  return (
    <Field name="model">
      {({ field: { name } }: FieldProps) => (
        <>
          <ModelSelect
            onChange={({ id, country }) => {
              setFieldValue(`${name}.modelId`, id);
              setFieldValue(`${name}.modelCountry`, country);
              setFieldValue('dimensions', []);
              setFieldValue('manualDimensions', []);
            }}
            onMapperChange={mapperName => {
              setFieldValue(`${name}.modelMapper`, mapperName);
              setFieldValue('wordAttributes', [{ value: 'seed_score' }]);
            }}
            initialId={initialValues.model.modelId}
            modelInfo={true}
            hideDateSelect={true}
            withMapper={true}
            horizontalForm={true}
          />
          <ErrorMessage name="model">
            {() => (
              <Feedback>
                <FormattedMessage id="exploring.setup.models.model_required" />
              </Feedback>
            )}
          </ErrorMessage>
        </>
      )}
    </Field>
  );
};

export default ModelSelectStep;
