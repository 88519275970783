import { Typography } from '@mui/material';
import DeleteIconButton from 'components/iconButtons/DeleteIconButton';
import { ReactElement } from 'react';
import styled, { DefaultTheme } from 'styled-components';

export const BANNER_BREAKING_POINT = 1250;
export const BANNER_HIDE_BREAKING_POINT = 550;

type BannerProps = {
  title: ReactElement;
  content: ReactElement;
  image: string;
  color: keyof DefaultTheme['colors']['palette'];

  onClose?: () => void;
};

export function BannerLayout(props: BannerProps) {
  return (
    <Root $color={props.color}>
      <Body>
        <HeaderText>{props.title}</HeaderText>
        <BodyText>{props.content}</BodyText>
      </Body>
      <ImageContainer>
        <Image src={props.image} />
      </ImageContainer>

      {props.onClose && (
        <CloseContainer>
          <DeleteIconButton onClick={props.onClose} />
        </CloseContainer>
      )}
    </Root>
  );
}

const Root = styled.div<{ $color: keyof DefaultTheme['colors']['palette'] }>`
  max-height: 252px;
  border-radius: ${({ theme }) => theme.borderRadius.one};
  overflow: hidden;
  background-color: ${({ theme, $color }) => theme.colors.palette[$color]};

  display: flex;
  align-items: center;

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: ${({ theme }) => theme.spacings.one};
  }

  @media (max-width: ${BANNER_HIDE_BREAKING_POINT}px) {
    display: none;
  }
`;

const Body = styled.div`
  max-width: 350px;
  max-width: 750px;
  padding: ${({ theme }) => theme.spacings.six};

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.three};

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: ${({ theme }) => theme.spacings.one};
  }
`;

const HeaderText = styled(Typography).attrs({
  variant: 'h4',
  color: 'white'
})`
  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    ${({ theme }) => ({
      ...theme.typography.h5
    })}
  }
`;

const BodyText = styled(Typography).attrs({
  variant: 'body1',
  color: 'white'
})`
  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    ${({ theme }) => ({
      ...theme.typography.body2
    })}
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: 660px) {
    display: none;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  max-width: unset;
`;

const CloseContainer = styled.div`
  position: absolute;

  top: 10px;
  right: 15px;
`;
