import { TextField, Typography } from '@mui/material';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import NotificationHint from 'components/profile/components/pricing-plan-card/NotificationHint';
import LocalStorageKey from 'config/localStorageKey';
import { ModelAutocomplete } from 'features/embeddingModels/ModelAutocomplete';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import {
  FormValues,
  SocialPlatform
} from 'features/workflowSocialPostBuilder/SocialPostBuilderFormikProvider';
import { SocialPostBuilderStepLayout } from 'features/workflowSocialPostBuilder/SocialPostBuilderStepLayout';
import { SocialPlatformChip } from 'features/workflowSocialPostBuilder/steps/SocialPlatformChip';
import { SocialBuilder } from 'features/workflowSocialPostBuilder/WorkflowSchema';
import { useField, useFormikContext } from 'formik';
import { upperFirst } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const minimumTextLength = 3;

export const Step1 = () => {
  const translate = useTr();
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const { values, isValid } = useFormikContext<FormValues>();

  const [formikOutlineField, { error, touched }] = useField({
    name: 'outline',
    type: 'text'
  });
  const errorMessage = upperFirst(error);

  return (
    <SocialPostBuilderStepLayout
      nextButtonProps={{
        disabled:
          !isValid || values.outline.length < minimumTextLength || !values.socialPlatforms.length
      }}
    >
      <Typography variant="h6">
        <FormattedMessage id="social_post_builder.step1.title" />
      </Typography>

      {values.showOnboardingHint && (
        <NotificationHint>
          <ColoredTypography color="primary700">
            <FormattedMessage id="social_post_builder.social_platforms_step.onboarding_hint" />
          </ColoredTypography>
        </NotificationHint>
      )}

      <ModelAutocomplete
        name="embeddingModelId"
        models={models}
        onSelect={value => {
          localStorage.setItem(LocalStorageKey.AiWriterModel, value);
        }}
      />

      <FullWidthContainer>
        <SocialBuilder.Label labelId="social_post_builder.social_platforms_step.label" />

        <FlexContainer gap="two" direction="row" flexWrap="wrap">
          <SocialPlatformChip platform={SocialPlatform.Facebook} />
          <SocialPlatformChip platform={SocialPlatform.Instagram} />
          <SocialPlatformChip platform={SocialPlatform.LinkedIn} />
          <SocialPlatformChip platform={SocialPlatform.Twitter} />
          <SocialPlatformChip platform={SocialPlatform.YouTube} />
        </FlexContainer>
      </FullWidthContainer>

      <FullWidthContainer>
        <SocialBuilder.Label labelId="social_post_builder.outline_step.label" />

        <TextField
          {...formikOutlineField}
          placeholder={translate('social_post_builder.outline_step.placeholder')}
          autoComplete="none"
          error={!!error && touched}
          helperText={touched && error ? errorMessage : null}
          fullWidth={true}
          multiline={true}
        />
      </FullWidthContainer>
    </SocialPostBuilderStepLayout>
  );
};

const FullWidthContainer = styled(FlexContainer).attrs({ gap: 'one' })`
  width: 100%;
`;
