import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { IconButton } from '@mui/material';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

export function CommandTemplatesButton(props: { gtmId: string; onSelection: () => void }) {
  const { gtmId, onSelection } = props;
  return (
    <StyledButton {...withGtmInteraction(gtmId)} onClick={onSelection} size="small">
      <StickyNote2Icon fontSize="small" />
    </StyledButton>
  );
}

const StyledButton = styled(IconButton)`
  cursor: pointer;
  border: none;
  color: ${({ theme }) => theme.colors.componentsIconActive};

  &:hover {
    border: none;
    background-color: unset;
  }
`;
