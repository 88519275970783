import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { MenuProps } from '@mui/material/Menu';
import GptModelIcon from 'assets/icons/gpt-select.svg';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { getPricingPlan } from 'features/pricing/store/selectors';
import { PricingPlan } from 'features/pricing/types';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { UsageIndicatorType } from 'features/usageIndicator/UsageIndicatorType';
import { useGetCurrentUsageByType } from 'features/usageIndicator/useGetCurrentUsageByType';
import {
  GPT_MODELS,
  GptModel
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const allowedPricingPlans: Array<PricingPlan> = [
  PricingPlan.pro,
  PricingPlan.premium,
  PricingPlan.business,
  PricingPlan.enterprise
];

const menuOpeningTopLeftProps: Pick<MenuProps, 'anchorOrigin' | 'transformOrigin'> = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }
};

const menuOpeningBottomLeftProps: Pick<MenuProps, 'anchorOrigin' | 'transformOrigin'> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  }
};

type Props = {
  value: GptModel;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  onClick?: () => void;
  menuOpeningDirection?: 'top' | 'bottom';
};

export function GptSelect({ value, onChange, onClick, menuOpeningDirection = 'top' }: Props) {
  const usageResult = useGetCurrentUsageByType(UsageIndicatorType.gpt4);
  const isGpt4LimitReached =
    usageResult.data?.isUnlimited === false && usageResult.data?.isAvailable === false;

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const pricingPlan = useAppSelector(getPricingPlan);
  const isAllowedToSelectGpt4 = allowedPricingPlans.includes(pricingPlan);

  const isGpt4Disabled = !isAllowedToSelectGpt4 || isGpt4LimitReached;

  const handleUpgradeClick = () => {
    showUpgradeSubscriptionModal();
  };

  return (
    <StyledSelect
      size="small"
      value={value}
      onChange={e => onChange(e)}
      onClick={onClick}
      sx={{
        '& fieldset': {
          border: 'none'
        }
      }}
      MenuProps={{
        ...(menuOpeningDirection === 'top' ? menuOpeningTopLeftProps : menuOpeningBottomLeftProps)
      }}
      inputProps={{ IconComponent: () => null }}
      renderValue={() => <SelectIcon src={GptModelIcon} alt="GPT model icon" />}
    >
      {!isAllowedToSelectGpt4 && (
        <UpgradeMenuItem onClick={handleUpgradeClick}>
          <FormattedMessage id="aiWriter.inspirations.chat.change_GPT_model.GPT_4.upgrade" />
        </UpgradeMenuItem>
      )}
      <MenuItem
        {...withGtmInteraction(gtmIds.aiWriter.chat.selectGptModel)}
        selected={value === GPT_MODELS.GPT_3_5}
        value={GPT_MODELS.GPT_3_5}
      >
        <FormattedMessage id="aiWriter.inspirations.chat.change_GPT_model.GPT_3_5" />
      </MenuItem>
      <MenuItem
        {...withGtmInteraction(gtmIds.aiWriter.chat.selectGptModel)}
        disabled={isGpt4Disabled}
        selected={value === GPT_MODELS.GPT_4}
        value={GPT_MODELS.GPT_4}
      >
        <FormattedMessage id="aiWriter.inspirations.chat.change_GPT_model.GPT_4" />
      </MenuItem>
    </StyledSelect>
  );
}

const SelectIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const UpgradeMenuItem = styled(MenuItem)`
  color: #ed6c02;
`;

const StyledSelect = styled(Select)`
  max-width: 160px;

  & .MuiInputBase-input {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacings.small};
    // Aligns the height to the current template button style
    padding: 2px 0px;
  }

  & .MuiListItemText-primary {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  && .MuiSelect-outlined {
    padding: 5px;
  }
`;
