import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { GetPromptResponse } from 'services/api/wordEmbedding/types';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type GetPromptsSuccessDto = GetPromptResponse;

export const httpGetPrompts = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCurrentUserQueryKeyPrefix(), 'textGeneration', 'prompts', 'list'] as const;
  },
  callEndpoint: () => {
    return backofficeClient
      .get<GetPromptsSuccessDto>('/word-embedding/get_prompts', {
        // /api/v1 is a pass-through to backoffice API
        // /api/word-embedding/get_prompts is a pass-through to data science/neuro API
        baseURL: '/api'
      })
      .then(response => response.data);
  }
});
