import { ModularWorkflowDataAndStructureProvider } from 'features/modular-workflow/runner/ModularWorkflowDataAndStructureProvider';
import { ModularWorkflowRunView } from 'features/modular-workflow/runner/ModularWorkflowRunView';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { CommonWorkflowOuterRoot } from 'features/workflow/CommonWorkflowContainer';

export const ModulareWorkflowRunPage = () => {
  useEnableSidebarOnMount();

  return (
    <CommonWorkflowOuterRoot>
      <ModularWorkflowDataAndStructureProvider>
        <ModularWorkflowRunView />
      </ModularWorkflowDataAndStructureProvider>
    </CommonWorkflowOuterRoot>
  );
};
