import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { getRoutePath, routesConfig, SideBarData } from 'config/routes';
import { setCurrentTab as setCurrentAiTesterTab } from 'features/aiTester/store/actions/tabs/actions';
import { setCurrentTab as setCurrentAiWriterTab } from 'features/aiWriter/store/actions/tabs/actions';
import { canUpgradePricingPlan, getIsApiCustomer } from 'features/customer/store/selectors';
import { getSideBarFeatures } from 'features/features/store/selectors';
import { SidebarItemAccount } from 'features/pageSidebar/sidebarItems/actions/SidebarItemAccount';
import { SidebarItemHelpCenter } from 'features/pageSidebar/sidebarItems/actions/SidebarItemHelpCenter';
import { SidebarItemNotificationCenter } from 'features/pageSidebar/sidebarItems/actions/SidebarItemNotificationCenter';
import { SidebarItemReferralBonusPage } from 'features/pageSidebar/sidebarItems/actions/SidebarItemReferralBonusPage';
import { SidebarItemUpgradeButton } from 'features/pageSidebar/sidebarItems/actions/SidebarItemUpgradeButton';
import { SidebarLogo } from 'features/pageSidebar/sidebarItems/SidebarLogo';
import { SidebarNavItem } from 'features/pageSidebar/sidebarItems/SidebarNavItem';
import { useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import { TrialExpiryProgressBar } from 'features/pricing/TrialExpiryProgressBar';
import { useLocation } from 'react-router';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled, { css } from 'styled-components';

const drawerWidth = 260;
const drawerWidthMinimized = 64;

export function Sidebar() {
  const dispatch = useAppDispatch();

  const isSidebarExpanded = useSidebarStore(state => state.isExpanded);
  const isSidebarEnabled = useSidebarStore(state => state.isEnabled);

  const activeFeatures = useGetActiveFeatures();

  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const showUpgrade = useAppSelector(canUpgradePricingPlan);

  const toggleSidebar = useSidebarStore(state => state.toggleSidebar);
  const expandSidebar = useSidebarStore(state => state.expandSidebar);

  const location = useLocation();
  const isAiWriterLocation = location.pathname === getRoutePath('aiWriter');

  const handleSidebarFeatureClick = (config: SideBarData) => {
    // If the user clicks on ai writer or tester,
    // close the current document and show the document overview
    // TODO: Remove as soon as we've unique URLs per document
    if (config.path === getRoutePath('aiWriter')) {
      dispatch(setCurrentAiWriterTab(''));
    }

    if (config.path === getRoutePath('aiTester')) {
      dispatch(setCurrentAiTesterTab(''));
    }
  };

  const handleSidebarClick = () => {
    if (!isSidebarExpanded) {
      expandSidebar();
    }
  };

  if (!isSidebarEnabled) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }} onClick={handleSidebarClick}>
      {isSidebarExpanded && isAiWriterLocation && <Overlay />}
      <Drawer
        // Note: This prevents the backdrop from working, only "temporary" drawers supports it
        //       We're using a click away listener instead
        variant="permanent"
        open={isSidebarExpanded}
        $isOpen={isSidebarExpanded}
      >
        <CloseButtonBox onClick={toggleSidebar}>
          <ExtendIcon $isExtended={isSidebarExpanded} />
        </CloseButtonBox>

        <DrawerHeader>
          <SidebarLogo />
        </DrawerHeader>
        <DrawerBody>
          <ScrollableList>
            {activeFeatures.map(config => (
              <SidebarNavItem
                gtmId={gtmIdsMap[config.path]}
                key={config.path}
                to={config.path}
                icon={config.icon}
                name={config.title}
                tooltip={config.title}
                onClick={() => handleSidebarFeatureClick(config)}
              />
            ))}
          </ScrollableList>

          <ScrollableList>
            {isSidebarExpanded && <TrialExpiryProgressBar />}
            {showUpgrade && <SidebarItemUpgradeButton />}
            <SidebarItemReferralBonusPage />
            {!isApiCustomer && <SidebarItemNotificationCenter />}
            <SidebarItemHelpCenter />
            <SidebarItemAccount />
          </ScrollableList>
        </DrawerBody>
      </Drawer>
    </Box>
  );
}

function useGetActiveFeatures() {
  const features = useAppSelector(getSideBarFeatures);

  return features.filter((config): config is SideBarData => !!config);
}

const gtmIdsMap = {
  [routesConfig.aiWriter.path]: gtmIds.sidebar.contentFlash,
  [routesConfig.aiImages.path]: gtmIds.sidebar.imageFlash,
  [routesConfig.aiTester.path]: gtmIds.sidebar.performanceFlash,
  [routesConfig.exploring.path]: gtmIds.sidebar.researchFlash
};

const openedMixin = css`
  width: ${drawerWidth}px;
`;

const closedMixin = css`
  width: calc(${drawerWidthMinimized}px + 1px);
`;

const Drawer = styled(MuiDrawer)<{ $isOpen?: boolean }>`
  position: absolute;

  width: ${drawerWidth}px;
  flex-shrink: 0;
  white-space: nowrap;
  box-sizing: border-box;

  ${({ $isOpen }) => ($isOpen ? openedMixin : closedMixin)};

  & .MuiDrawer-paper {
    ${({ $isOpen }) => ($isOpen ? openedMixin : closedMixin)};
    transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
    will-change: width, box-shadow, background-color;
    overflow: visible;
    // increased to a value higher than toolbar's z-index to ensure sidebar is not ever overlayed by the toolbar
    z-index: 1010;

    padding: ${({ theme }) => `${theme.spacings.four} ${theme.spacings.two}`};
    border-radius: 0;

    border-right-width: 0;
    border-right: ${({ theme }) => `1px solid ${theme.colors.divider}`};
    background-color: ${({ $isOpen, theme }) => ($isOpen ? theme.colors.white : 'transparent')};
  }
`;

const CloseButtonBox = styled(IconButton)`
  position: absolute;
  top: 65px;
  right: -10px;
  z-index: 1012;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 100px;
  border: ${({ theme }) => `1px solid ${theme.colors.divider}`};
  background: ${({ theme }) => theme.colors.commonWhiteMain};

  &:hover {
    background: ${({ theme }) => theme.colors.commonWhiteMain};
  }
`;

const ExtendIcon = styled(ArrowForwardIosIcon)<{ $isExtended?: boolean }>`
  width: 8px;
  height: 8px;

  color: #a19d9d;

  transform: ${({ $isExtended }) => ($isExtended ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const ScrollableList = styled(List)`
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0;
`;

const Overlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 100
});

const DrawerHeader = styled('div')`
  min-height: 33px;
  display: flex;
  align-items: flex-end;
  margin-left: ${({ theme }) => theme.spacings.two};
  margin-bottom: ${({ theme }) => theme.spacings.six};
`;

const DrawerBody = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
