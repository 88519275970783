import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import { Tooltip } from '@mui/material';
import { ELEMENT_OL, ELEMENT_UL } from '@udecode/plate-list';
import FlexContainer from 'components/FlexContainer';
import { AiImagesButton } from 'features/aiWriter/AiWriterTextEditor/AiImagesButton';
import { AlignTextDropdown } from 'features/aiWriter/AiWriterTextEditor/AlignTextDropdown';
import { ClearFormattingButton } from 'features/aiWriter/AiWriterTextEditor/ClearFormattingButton';
import { DocumentCustomizationButton } from 'features/aiWriter/AiWriterTextEditor/DocumentCustomizationButton';
import { EditorEmojiPickerButton } from 'features/aiWriter/AiWriterTextEditor/EditorEmojiPickerButton';
import { EditorIconFormatButton } from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { EditorListButton } from 'features/aiWriter/AiWriterTextEditor/EditorListButton';
import { EditorMarkButton } from 'features/aiWriter/AiWriterTextEditor/EditorMarkButton';
import { EditorMoreButton } from 'features/aiWriter/AiWriterTextEditor/EditorMoreButton';
import { EditorShareButton } from 'features/aiWriter/AiWriterTextEditor/EditorShareButton';
import EditorStatistics from 'features/aiWriter/AiWriterTextEditor/EditorStatistics';
import { EditorTagsButton } from 'features/aiWriter/AiWriterTextEditor/EditorTagsButton';
import { EditorToolbarMostOptionsDropdown } from 'features/aiWriter/AiWriterTextEditor/EditorToolbarMostOptionsDropdown';
import { EditorTransformButton } from 'features/aiWriter/AiWriterTextEditor/EditorTransformButton';
import { FlashScoreButton } from 'features/aiWriter/AiWriterTextEditor/FlashScoreButton';
import { HighlightButton } from 'features/aiWriter/AiWriterTextEditor/HighlightButton';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { useEditorCharactersCount } from 'features/aiWriter/AiWriterTextEditor/hooks/useEditorCharactersCount';
import { IndentButton } from 'features/aiWriter/AiWriterTextEditor/IndentButton';
import { LanguageToolsButton } from 'features/aiWriter/AiWriterTextEditor/languageToolButton/LanguageToolsButton';
import { LineHeightButton } from 'features/aiWriter/AiWriterTextEditor/LineHeightButton';
import { OutdentButton } from 'features/aiWriter/AiWriterTextEditor/OutdentButton';
import { TableDropdown } from 'features/aiWriter/AiWriterTextEditor/TableDropdown';
import { TodoListButton } from 'features/aiWriter/AiWriterTextEditor/TodoListButton';
import { trackEditorFormatAction } from 'features/aiWriter/AiWriterTextEditor/trackEditorFormatAction';
import { getIconColor } from 'features/aiWriter/AiWriterTextEditor/utils/getIconColor';
import { getSelectionTextWithSeparatedBlocks } from 'features/aiWriter/AiWriterTextEditor/utils/getSelectionTextWithSeparatedBlocks';
import { FlashActionsTopToolbarButton } from 'features/aiWriter/slashCommands/FlashActionsTopToolbarButton';
import { DOCUMENT_TOP_BAR_SCREEN_WIDTH_THRESHOLD } from 'features/document-top-bar/DocumentPageTopBar';
import { useIsFlashScoreEnabled } from 'features/flashScore/useIsFlashScoreEnabled';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReactElement } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

const EDITOR_TOOLBAR_SCREEN_WIDTH_THRESHOLD = 900;

export const EditorTopToolbar = (): ReactElement | null => {
  const editor = useEditor();

  const { width, ref } = useResizeDetector();

  const isFlashScoreEnabled = useIsFlashScoreEnabled();

  const undoClickHandler = () => {
    trackEditorFormatAction('undo');

    editor?.undo();
  };

  const redoClickHandler = () => {
    trackEditorFormatAction('redo');

    editor?.redo();
  };

  const selectedText = editor ? getSelectionTextWithSeparatedBlocks(editor) : '';

  const charsCount = useEditorCharactersCount();

  const returnWordsCount = () => {
    if (charsCount === 0) {
      // we need this cause if we don't have any character inserted in the editor we should show 0
      // but by default Plate shows 1 becuase we have some initial nodes insterted
      return <WordsCounter>0</WordsCounter>;
    }
    return selectedText.length > 0 ? (
      <Tooltip title={<FormattedMessage id="aiWriter.toolbar.words_count.tooltip" />}>
        <WordsCounter>{selectedText.length}</WordsCounter>
      </Tooltip>
    ) : (
      <EditorStatistics />
    );
  };

  if (!editor) {
    return null;
  }

  const isSqueezed =
    width &&
    width < EDITOR_TOOLBAR_SCREEN_WIDTH_THRESHOLD &&
    width >= DOCUMENT_TOP_BAR_SCREEN_WIDTH_THRESHOLD;

  const isMoreSqueezed = width && width < DOCUMENT_TOP_BAR_SCREEN_WIDTH_THRESHOLD;

  const squeezedRenderedComponent = isSqueezed ? (
    <>
      <EditorEmojiPickerButton styleVariant="icon" />
      <EditorMoreButton />

      <Separator />

      {isFlashScoreEnabled && (
        <FlashScoreButton
          {...withGtmInteraction(gtmIds.aiWriter.flashScore.topToolbar)}
          styleVariant="icon"
        />
      )}
      <FlashActionsTopToolbarButton
        selectedText={selectedText}
        styleVariant="icon"
        gtmId={gtmIds.aiWriter.editor.selectionToolbar.flashActions}
      />
      <Separator />
    </>
  ) : (
    <>
      <Separator />
      <AlignTextDropdown styleVariant="icon" />
      <LineHeightButton styleVariant="icon" />
      <EditorListButton styleVariant="icon" nodeType={ELEMENT_UL} />
      <EditorListButton styleVariant="icon" nodeType={ELEMENT_OL} />
      <TodoListButton styleVariant="icon" />
      <IndentButton styleVariant="icon" />
      <OutdentButton styleVariant="icon" />
      <TableDropdown styleVariant="icon" />
      <Separator />
      <EditorEmojiPickerButton styleVariant="icon" />
      <AiImagesButton styleVariant="icon" />
      <Separator />
      <EditorShareButton styleVariant="icon" />
      <EditorTagsButton styleVariant="icon" />
      <Separator />
      {isFlashScoreEnabled && (
        <FlashScoreButton
          {...withGtmInteraction(gtmIds.aiWriter.flashScore.topToolbar)}
          styleVariant="icon"
        />
      )}
    </>
  );

  if (isMoreSqueezed) {
    return (
      <Menu ref={ref}>
        <FlexContainer direction="row" gap="xsmall" alignItems="center">
          <EditorIconFormatButton $isActive={false} onClick={undoClickHandler}>
            <Tooltip
              arrow
              title={
                <FormattedMessage id="aiWriter.toolbar.undo" macId="aiWriter.toolbar.undo.mac" />
              }
              placement="bottom"
            >
              <UndoIcon color={getIconColor(false)} fontSize="small" />
            </Tooltip>
          </EditorIconFormatButton>
          <EditorIconFormatButton $isActive={false} onClick={redoClickHandler}>
            <Tooltip
              arrow
              title={
                <FormattedMessage id="aiWriter.toolbar.redo" macId="aiWriter.toolbar.redo.mac" />
              }
              placement="bottom"
            >
              <RedoIcon color={getIconColor(false)} fontSize="small" />
            </Tooltip>
          </EditorIconFormatButton>
          <Separator />
          <EditorTransformButton />
          <EditorMarkButton styleVariant="icon" format="bold" />
          <EditorMarkButton styleVariant="icon" format="underline" />
          <EditorMarkButton styleVariant="icon" format="italic" />
          <EditorEmojiPickerButton styleVariant="icon" />
          <EditorToolbarMostOptionsDropdown />
          <Separator />
          {isFlashScoreEnabled && (
            <FlashScoreButton
              {...withGtmInteraction(gtmIds.aiWriter.flashScore.topToolbar)}
              styleVariant="icon"
            />
          )}
          <FlashActionsTopToolbarButton
            selectedText={selectedText}
            styleVariant="icon"
            gtmId={gtmIds.aiWriter.editor.selectionToolbar.flashActions}
          />
          <Separator />
        </FlexContainer>
        {returnWordsCount()}
      </Menu>
    );
  }

  return (
    <Menu ref={ref}>
      <FlexContainer direction="row" gap="xsmall" alignItems="center">
        <EditorIconFormatButton $isActive={false} onClick={undoClickHandler}>
          <Tooltip
            arrow
            title={
              <FormattedMessage id="aiWriter.toolbar.undo" macId="aiWriter.toolbar.undo.mac" />
            }
            placement="bottom"
          >
            <UndoIcon color={getIconColor(false)} fontSize="small" />
          </Tooltip>
        </EditorIconFormatButton>
        <EditorIconFormatButton $isActive={false} onClick={redoClickHandler}>
          <Tooltip
            arrow
            title={
              <FormattedMessage id="aiWriter.toolbar.redo" macId="aiWriter.toolbar.redo.mac" />
            }
            placement="bottom"
          >
            <RedoIcon color={getIconColor(false)} fontSize="small" />
          </Tooltip>
        </EditorIconFormatButton>
        <Separator />
        <LanguageToolsButton styleVariant="icon" />
        <DocumentCustomizationButton styleVariant="icon" />
        <Separator />
        <EditorTransformButton />
        <EditorMarkButton styleVariant="icon" format="bold" />
        <EditorMarkButton styleVariant="icon" format="underline" />
        <EditorMarkButton styleVariant="icon" format="italic" />
        <HighlightButton styleVariant="icon" />
        <ClearFormattingButton styleVariant="icon" />
        {squeezedRenderedComponent}
      </FlexContainer>
      {returnWordsCount()}
    </Menu>
  );
};

const Menu = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  opacity: 1;
  border-radius: 20px;
  z-index: 1;
  border: none;
  background-color: #f5f5f5;
  min-height: 36px;
  padding: 0 8px;
`;

const Separator = styled.div`
  display: inline-block;
  border-left: 0.5px solid ${({ theme }) => theme.colors.aiWriterSidebarBorderColor};
  height: 40px;
`;

const WordsCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.blackInactive};
  cursor: pointer;
  padding-right: 8px;
  font-size: ${({ theme }) => theme.fontSizes.caption};
`;
