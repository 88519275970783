import { AnyAction, configureStore, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import LocalStorageKey from 'config/localStorageKey';
import fileSaver from 'file-saver';
import { throttle } from 'lodash';
import createSagaMiddleware from 'redux-saga';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';
import rootReducer from 'store/rootReducer';
import rootSaga from 'store/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: rootReducer,
  enhancers: [
    // TODO: Inject into redux? Add current state to error logs?
    /*
    createReduxEnhancer({
      stateTransformer: (state: RootState): unknown => ({
        aiWriter: {
          projects: state.aiWriter.projects.map(project => ({
            id: project.id,
            name: project.name
          })),
          currentTab: state.aiWriter.currentTab,
          tabs: Object.keys(state.aiWriter.tabs)
        },
        customer: {
          id: state.customer.id,
          email: state.customer.email,
          timezone: state.customer.timezone,
          isApiCustomer: state.customer.isApiCustomer,
          hasTeam: state.customer.hasTeam,
          pricing: state.customer.pricing
        }
      })
    })
    */
  ],
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunkMiddleware, sagaMiddleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

sagaMiddleware.run(rootSaga);

store.subscribe(
  throttle(() => {
    const state = store.getState();
    localStorage.setItem(LocalStorageKey.AppLanguage, state.language);
    localStorage.setItem(
      LocalStorageKey.ExplorerVerticalLayout,
      `${state.explorer.pageLayoutVertical}`
    );
    localStorage.setItem(LocalStorageKey.ToolbarLocked, `${state.toolbar.toolbarLocked}`);
  }, 1000)
);

if (window.Cypress) {
  window.store = store;
}

if (process.env.REACT_APP_DUMP_STATE_ENABLED === 'true') {
  window.dumpState = () => {
    const state = store.getState();
    const json = JSON.stringify(state);
    fileSaver.saveAs(
      new Blob([json], { type: 'application/json' }),
      `state_${new Date().toJSON()}.json`
    );
  };
}

export default store;
