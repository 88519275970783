import { Button, Typography } from '@mui/material';
import { ReactComponent as IconNeuroflashLogoSVG } from 'assets/neuroflashLogo.svg';
import FlexContainer from 'components/FlexContainer';
import { useIsDesktop } from 'features/onboardingJourney/useIsDesktop';
import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

export const onboardingMobileThreshold = '922px';
export const onboardingContentWidth = '400px';

type OnboardingViewProps = PropsWithChildren<{
  withLogo?: boolean;
  title: ReactNode;
  subtitle?: ReactNode;
  stepper?: ReactNode;
}>;

export function OnboardingRoot({
  children,
  title,
  subtitle,
  stepper,
  withLogo = true
}: OnboardingViewProps) {
  const isDesktop = useIsDesktop();
  return (
    <Root>
      {withLogo && <NeuroflashLogo />}
      {isDesktop && stepper}

      <Heading>
        <Title>{title}</Title>
        <Subtitle variant="body1" color="textSecondary">
          {subtitle}
        </Subtitle>
      </Heading>
      {children}
    </Root>
  );
}

const Root = styled(FlexContainer).attrs({
  alignItems: 'center',
  gap: 'four'
})`
  height: 100%;
  margin-top: 10%;

  @media (max-width: ${onboardingMobileThreshold}) {
    margin-top: ${({ theme }) => theme.spacings.small};
    gap: ${({ theme }) => theme.spacings.medium};
  }
`;

const Heading = styled(FlexContainer)`
  max-width: 450px;
  gap: ${({ theme }) => theme.spacings.one};
`;

const NeuroflashLogo = styled(IconNeuroflashLogoSVG)`
  position: absolute;
  top: ${({ theme }) => theme.spacings.medium};
  left: ${({ theme }) => theme.spacings.medium};
  margin-bottom: 5vh;

  @media (max-width: ${onboardingMobileThreshold}) {
    margin-top: 0;
    margin-bottom: 0;
    position: static;
  }
`;

const Title = styled(Typography).attrs({
  variant: 'h6'
})`
  text-align: center;

  @media (max-width: ${onboardingMobileThreshold}) {
    font-size: 20px;
    font-weight: 500;
  }
`;

const Subtitle = styled(Typography)``;

export const Footer = styled(FlexContainer)`
  max-width: ${onboardingContentWidth};
  align-items: center;
  width: 100%;
`;

export const NextButton = styled(Button)``;
