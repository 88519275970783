import { CommonWorkflowOuterRoot } from 'features/workflow/CommonWorkflowContainer';
import { SocialPostBuilderFormikProvider } from 'features/workflowSocialPostBuilder/SocialPostBuilderFormikProvider';
import {
  SocialPostBuilderStepContext,
  SocialPostBuilderStepProvider,
  Steps
} from 'features/workflowSocialPostBuilder/SocialPostBuilderStepProvider';
import { Step1 } from 'features/workflowSocialPostBuilder/steps/Step1';
import { Step2 } from 'features/workflowSocialPostBuilder/steps/Step2';
import { Step3Generate } from 'features/workflowSocialPostBuilder/steps/Step3Generate';
import { useContext } from 'react';

export const SocialPostBuilderPage = () => (
  <CommonWorkflowOuterRoot>
    <SocialPostBuilderFormikProvider>
      <SocialPostBuilderStepProvider>
        <SocialPostBuilderStepRenderer />
      </SocialPostBuilderStepProvider>
    </SocialPostBuilderFormikProvider>
  </CommonWorkflowOuterRoot>
);

const SocialPostBuilderStepRenderer = () => {
  const { currentStep } = useContext(SocialPostBuilderStepContext);

  switch (currentStep) {
    case Steps.Step1:
      return <Step1 />;
    case Steps.Step2:
      return <Step2 />;
    case Steps.Step3:
      return <Step3Generate />;
    default:
      return null;
  }
};
