import FlexContainer from 'components/FlexContainer';
import React from 'react';
import styled from 'styled-components';

type Props = {
  buttons: React.ReactNode[];
};

export const MobileOnboardingFooter = ({ buttons }: Props) => {
  const [nextButton, skipButton] = buttons;
  return (
    <Footer>
      <NextButton>{nextButton}</NextButton>
      <SkipButton>{skipButton}</SkipButton>
    </Footer>
  );
};

export const Footer = styled(FlexContainer)`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  min-height: 54px;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding-inline: ${({ theme }) => theme.spacings.medium};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
`;

const NextButton = styled.div`
  min-width: 100px;
`;
const SkipButton = styled.div`
  min-width: 100px;
`;
