import { TextInputField } from 'features/workflowBlogPostBuilder/steps/components/TextInputField';
import { FormattedMessage } from 'react-intl';
import useTr from 'utils/hooks/useTr';

import * as CommonStyled from './common.styles';

export const SelectTitleStep = () => {
  const translate = useTr();

  return (
    <>
      <CommonStyled.Title>
        <FormattedMessage id="blog_post_builder.steps.title.title" />
      </CommonStyled.Title>

      <TextInputField
        name="title"
        placeholder={translate('blog_post_builder.steps.title.placeholder')}
      />
    </>
  );
};
