import FlexContainer from 'components/FlexContainer';
import GraphExportDropdown from 'components/GraphExportDropdown';
import LayoutSwitchButton from 'features/explorer/LayoutSwitchButton';
import {
  getActiveTab,
  getExportNameSelector,
  getTabHasData
} from 'features/explorer/store/selectors';
import { ConceptFlashTab } from 'features/explorer/store/types';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const exportToSheet = (data: ConceptFlashTab, exportAll: boolean, filename: string) => {
  import('../../utils/excelExport').then(({ exportConceptTableToFile }) => {
    exportConceptTableToFile(data, exportAll, filename);
  });
};

const TabActions = () => {
  const tr = useTr();
  const activeTab = useAppSelector(getActiveTab);
  const hasData = useAppSelector(getTabHasData);
  const exportName = useAppSelector(getExportNameSelector)(tr);

  return (
    <TopAlignedFlexContainer direction="row" alignItems="center">
      {hasData && (
        <GraphExportDropdown
          filename={exportName}
          svgElement="#bubble-graph"
          additionalItems={[
            {
              isDivider: true
            },
            {
              value: tr('words_table.export_to_excel'),
              onClick: () => exportToSheet(activeTab, false, exportName)
            },
            {
              value: tr('words_table.export_to_excel_all'),
              onClick: () => exportToSheet(activeTab, true, exportName)
            }
          ]}
        />
      )}

      <LayoutSwitchButton />
    </TopAlignedFlexContainer>
  );
};

const TopAlignedFlexContainer = styled(FlexContainer)`
  align-self: flex-start;
`;

export default TabActions;
