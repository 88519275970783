import { LabelRounded } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { AiWriterProjectTagsDropdownMenu } from 'features/aiWriter/AiWriterProjectOverview/tags/AiWriterProjectTagsDropdownMenu';
import {
  EditorFormatButton,
  EditorFormatButtonProps,
  EditorIconFormatButton
} from 'features/aiWriter/AiWriterTextEditor/EditorIconFormatButton';
import { loadTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/loadTabThunk';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { useGetProjectById } from 'features/aiWriter/useGetProjectById';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useMenu } from 'utils/hooks/useMenu';

type EditorTagsButtonProps = {
  styleVariant?: EditorFormatButtonProps;
  onClose?: () => void;
};

export function EditorTagsButton({ onClose, styleVariant }: EditorTagsButtonProps) {
  const activeDocumentId = useAppSelector(getActiveTab).id;
  const aiWriterProjects = useAppSelector(state => state.aiWriter.projects);
  const currentProject = aiWriterProjects.find(project => project.id === activeDocumentId);
  const aiWriterProjectTags = useAppSelector(state =>
    activeDocumentId ? state.aiWriter.tabs[activeDocumentId]?.tags : []
  );

  const {
    anchorEl: tagsAnchorEl,
    isOpen: isTagsMenuOpen,
    onTriggerClick: onTagsTriggerClick,
    onMenuClose: onTagsMenuClose
  } = useMenu();

  const { fetchProject } = useGetProjectById();
  const dispatch = useAppDispatch();

  const handleOpenTagsDropdown = (event: React.MouseEvent<HTMLElement>) => {
    onTagsTriggerClick(event);
    GAEvents.tagsIconClickedInDocumentEditor();
  };

  const handleClose = () => {
    onTagsMenuClose();
    onClose && onClose();
  };

  const onUpdateDocumentTags = () => {
    if (!activeDocumentId) {
      return;
    }

    fetchProject(
      { id: activeDocumentId },
      {
        onSuccess: project => {
          dispatch(loadTabThunk(project));
        }
      }
    );
  };

  const menu = () => (
    <AiWriterProjectTagsDropdownMenu
      anchorEl={tagsAnchorEl}
      isOpen={isTagsMenuOpen}
      handleCloseMenu={handleClose}
      tags={aiWriterProjectTags}
      project={currentProject}
      onCreateTag={onUpdateDocumentTags}
    />
  );

  if (styleVariant === 'icon') {
    return (
      <>
        <EditorIconFormatButton onClick={handleOpenTagsDropdown}>
          <Tooltip arrow title={<FormattedMessage id="common.tags" />} placement="bottom">
            <LabelRounded fontSize="small" />
          </Tooltip>
        </EditorIconFormatButton>
        {menu()}
      </>
    );
  }

  return (
    <>
      <EditorFormatButton onClick={handleOpenTagsDropdown}>
        <LabelRounded fontSize="small" />
        <FormattedMessage id="common.tags" />
      </EditorFormatButton>
      {menu()}
    </>
  );
}
