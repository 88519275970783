import SendIcon from '@mui/icons-material/Send';
import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { MessageConfig } from 'features/aiWriter/AiWriterProjectOverview/chatForm/CreateChatForm';
import { useGetProjectOrPreselectedLanguageAndCountry } from 'features/aiWriter/hooks/useGetProjectOrPreselectedLanguageAndCountry';
import { usePostHog } from 'posthog-js/react';
import { ChangeEvent, KeyboardEvent } from 'react';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import { ChatMessageSource, GAEvents } from 'services/tracking/GAEvents';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  modelId: string;
  isSending: boolean;
  value: MessageConfig;
  sendButtonConfig: { gtmId: string; disabled?: boolean };

  onChange: (message: MessageConfig) => void;
  onSend: (text: string) => void;
};

export function HomeMessageInput({
  modelId,
  isSending,
  value,
  sendButtonConfig,
  onChange,
  onSend
}: Props) {
  const translate = useTr();

  const postHog = usePostHog();

  const { text } = value;

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      sendMessage({ source: 'enter' });
    }
  }

  function handleClick() {
    sendMessage({ source: 'button' });
  }

  function sendMessage({ source }: { source: ChatMessageSource }) {
    GAEvents.sentChatMessage({ source });
    postHog?.capture('CF - chat message sent via overview');
    onSend(text);
  }

  function selectAdornment() {
    if (isSending) {
      return <CircularProgress size={24} />;
    }

    return (
      <IconButton
        {...withGtmInteraction(sendButtonConfig.gtmId)}
        type="button"
        onClick={handleClick}
        aria-label={translate('chat.message_input.placeholder')}
        disabled={!text.length || sendButtonConfig.disabled}
      >
        <SendIcon />
      </IconButton>
    );
  }

  const { language, country } = useGetProjectOrPreselectedLanguageAndCountry(modelId);

  const params = { language, country };
  useQuery({
    queryKey: httpGetDefaultPersonality.makeQueryKey(params),
    queryFn: () => httpGetDefaultPersonality.callEndpoint(params),
    onSuccess: data => {
      if (data) {
        onChange({ ...value, personality: data });

        return;
      }

      onChange({ ...value, personality: undefined });
    }
  });

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({ ...value, text: e.currentTarget.value });
  };

  return (
    <StyledTextField
      value={text}
      onChange={handleTextFieldChange}
      onKeyDown={handleKeyDown}
      aria-label={translate('chat.message_input.aria_label')}
      placeholder={translate('chat.message_input.placeholder')}
      disabled={isSending}
      multiline
      rows={4}
      maxRows={4}
      fullWidth
      InputProps={{
        endAdornment: (
          <StyledInputAdornment position="end">{selectAdornment()}</StyledInputAdornment>
        ),
        disableUnderline: true
      }}
    />
  );
}

const StyledTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.colors.commonWhiteMain};
`;

const StyledInputAdornment = styled(InputAdornment)`
  position: absolute;
  right: 30px;
  bottom: 30px;
`;
