import { ReactComponent as SidebarlogoIcon } from 'assets/icon-neuroflash-logo-sidebar.svg';
import { ReactComponent as NeuroflashNameLogoSVG } from 'assets/nf-solo-name.svg';
import { getRoutePath } from 'config/routes';
import { mkSidebarAnimation } from 'features/pageSidebar/sidebarItems/SidebarItem';
import { useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import { useNavigate } from 'react-router';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';

export const SidebarLogo = () => {
  const isSidebarExpanded = useSidebarStore(state => state.isExpanded);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(getRoutePath('home'));
  };

  return (
    <StyledContainer onClick={handleClick} {...withGtmInteraction(gtmIds.sidebar.neuroflashIcon)}>
      <SidebarlogoIcon width={22} height={22} />
      <StyledNeuroflashNameLogo $isSidebarExtended={isSidebarExpanded} />
    </StyledContainer>
  );
};

const StyledContainer = styled.button`
  ${resetButton};
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const StyledNeuroflashNameLogo = styled(NeuroflashNameLogoSVG)<{ $isSidebarExtended: boolean }>`
  margin-left: 8px;
  display: ${({ $isSidebarExtended }) => ($isSidebarExtended ? 'block' : 'none')};
  ${({ $isSidebarExtended }) => mkSidebarAnimation($isSidebarExtended)}
`;
