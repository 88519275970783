import { Button, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CloseModal } from 'components/modals/types';
import Toast from 'components/toasts/Toast';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { informationModalHeight } from 'features/information/InformationModal';
import { LoadingAnimationWithTip } from 'features/loading-spinner/LoadingAnimationWithTip';
import { personalityTips } from 'features/loading-spinner/tipLists';
import {
  ModalBodyWithColumnDirectionAndSpaceBetween,
  ModalWithDividedHeaderLayout
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  httpCreateInformationFromUrl,
  URLString
} from 'services/backofficeIntegration/http/endpoints/infomration/httpCreateInformationFromUrl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';
import useTr from 'utils/hooks/useTr';

type Props = {
  url: string;

  setUrl: (value: string) => void;
  onSubmit: (value: string) => void;
  onBackClick: () => void;
} & CloseModal;

export function ShareUrlView(props: Props) {
  const { url, onSubmit, setUrl } = props;
  const [debouncedText] = useDebounce(url, 1000);
  const [isUrlValid, setIsUrlValid] = useState(true);

  const translate = useTr();

  const { mutate: scrapUrl, isLoading: isScrapeLoading } = useMutation({
    mutationFn: (url: URLString) => httpCreateInformationFromUrl.callEndpoint({ url }),
    onSuccess(data) {
      if (data.status) {
        onSubmit(data.data.content);
        return;
      }

      if (data.message === 'INFORMATION_SCRAPE_ERROR') {
        Toast.backendError('information.modal.share_url.INFORMATION_SCRAPE_ERROR');
        return;
      }

      Toast.apiError();
    }
  });

  useEffect(() => {
    validateUrl(debouncedText);
  }, [debouncedText]);

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const handleConfirmClick = () => {
    scrapUrl(props.url);
  };

  const validateUrl = (url: URLString) => {
    if (url === '') {
      setIsUrlValid(true);
    } else {
      try {
        new URL(url);
        setIsUrlValid(true);
      } catch (_) {
        setIsUrlValid(false);
      }
    }
  };

  const isNextDisabled = !isUrlValid || url === '';

  return (
    <ModalWithDividedHeaderLayout
      title={<FormattedMessage id="information.modal.title" />}
      description={<FormattedMessage id="information.modal.share_url.body" />}
      helpLink="aiWriter.inspirations.aiwriter.main.information.help_link"
      closeModal={props.closeModal}
      height={informationModalHeight}
      slots={{
        bodyContainer: ModalBodyWithColumnDirectionAndSpaceBetween
      }}
      footer={
        <>
          <Button variant="text" onClick={props.onBackClick}>
            <FormattedMessage id="common.back" />
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmClick}
            disabled={isNextDisabled}
            {...withGtmInteraction(gtmIds.brandHub.information.createdInformationFromShareURL)}
          >
            <FormattedMessage id="common.confirm" />
          </Button>
        </>
      }
    >
      <Body>
        {isScrapeLoading ? (
          <LoadingBox>
            <LoadingAnimationWithTip tips={personalityTips} />
          </LoadingBox>
        ) : (
          <TextField
            value={url}
            onChange={handleChangeText}
            fullWidth
            error={!isUrlValid}
            helperText={!isUrlValid && translate('information.modal.share_url.input.error_message')}
            placeholder={translate('information.modal.share_url.input.placeholder')}
          />
        )}
      </Body>
    </ModalWithDividedHeaderLayout>
  );
}

const Body = styled.div`
  flex: 1;
`;

const LoadingBox = styled.div`
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;
