import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { IconButton, Typography } from '@mui/material';
import { insertNodes, Value } from '@udecode/plate-common';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import SeoAddAllButton from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/SeoAddAllButton';
import { SeoSearchResult } from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/SeoSearchResult/SeoSearchResult';
import useReturnToMainSeoStep from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/useReturnToMainSeoStep';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import { getAverageWordCount, getSeoAnalysisResults } from 'features/aiWriter/store/selectors';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const SeoAnalysisSearchResultsSubStep = () => {
  const editor = useEditor();
  const dispatch = useAppDispatch();

  const seoAnalysisResults = useAppSelector(getSeoAnalysisResults);
  const averageWordCount = useAppSelector(getAverageWordCount);

  const returnToMainSeoStep = useReturnToMainSeoStep();

  const addAllToEditor = useCallback(() => {
    if (!editor) {
      return;
    }

    if (!seoAnalysisResults) {
      return;
    }

    const content = seoAnalysisResults.reduce(
      (prev, curr) => [
        ...prev,
        { type: 'h3', children: [{ text: curr.title }] },
        { type: 'paragraph', children: [{ text: curr.description }] }
      ],
      [] as Value
    );

    insertNodes(editor, content);

    dispatch(updateCurrentProjectInBackgroundThunk());
  }, [editor, seoAnalysisResults, dispatch]);

  if (!seoAnalysisResults) {
    return null;
  }

  return (
    <FlexContainer gap="medium">
      <FlexContainer direction="row" gap="three" alignItems="center">
        <IconButton onClick={returnToMainSeoStep}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h6">
          <FormattedMessage id="aiWriter.inspirations.seoAnalysis.title" />
        </Typography>
      </FlexContainer>

      <FlexContainer gap="small">
        <ColoredTypography variant="subtitle1" color="blackHighEmphasis">
          <FormattedMessage id="aiWriter.inspirations.seoAnalysis.search_results.competitors" />
        </ColoredTypography>
        <StatsContainer>
          <FlexContainer direction="row" justifyContent="space-between">
            <ColoredTypography color="blackHighEmphasis">
              <FormattedMessage id="aiWriter.inspirations.seoAnalysis.search_results.stats.average_words" />
            </ColoredTypography>
            <ColoredTypography color="blackHighEmphasis" fontWeight={500}>
              {averageWordCount}
            </ColoredTypography>
          </FlexContainer>
        </StatsContainer>
      </FlexContainer>
      <SeoAddAllButton onClick={addAllToEditor} />
      {seoAnalysisResults.map(result => (
        <SeoSearchResult key={result.link} result={result} />
      ))}
    </FlexContainer>
  );
};

const StatsContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};

  background-color: ${({ theme }) => theme.colors.surfaceCard};
`;
