import Toast from 'components/toasts/Toast';
import { initializeTab } from 'features/aiWriter/store/actions/tabs/actions';
import { configureCommonTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/configureCommonTabThunk';
import { InitializeTabThunkPayload } from 'features/aiWriter/store/actions/tabs/types';
import { AiWriterTab, SharingPermission } from 'features/aiWriter/store/types';
import { defaultText } from 'features/aiWriter/store/utils/defaults/defaultText';
import { aiWriterProjectFromApi } from 'features/aiWriter/store/utils/mappers/aiWriterProjectMappers';
import { aiWriterTabFromProject } from 'features/aiWriter/store/utils/mappers/aiWriterTabMappers';
import {
  aiWriterTextToApi,
  getProjectShortText
} from 'features/aiWriter/store/utils/mappers/aiWriterTextMappers';
import {
  getGetCountryEmbeddingModelGroupById,
  getGetEmbeddingModelsForCountry
} from 'features/embeddingModels/store/selectors';
import { collapseSidebar } from 'features/pageSidebar/useSidebarStore';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import AiWriterAPI from 'services/api/aiWriter';
import { handleApiCommonErrors } from 'services/api/utils/handleApiCommonErrors';
import { GAEvents } from 'services/tracking/GAEvents';
import { AppThunk } from 'store/store';

type TabConfigurationThunk = (tab: AiWriterTab) => AppThunk<void>;

export const initializeTabThunk =
  (
    payload: InitializeTabThunkPayload,
    tabConfiguration: TabConfigurationThunk | null = configureCommonTabThunk,
    config: { shouldCollapseSidebar?: boolean } = { shouldCollapseSidebar: true }
  ): AppThunk<void> =>
  async (dispatch, getState) => {
    const {
      audienceId,
      outputType,
      embeddingModelId,
      personalityId,
      keywords,
      keywords2,
      brief,
      name,
      tonality,
      isNewDocument,
      text
    } = payload;
    const state = getState();

    dispatch(initializeTab.request());

    const getCountryModelGroupById = getGetCountryEmbeddingModelGroupById(state);
    const getModelsForCountry = getGetEmbeddingModelsForCountry(state);

    const countryModelGroup = getCountryModelGroupById(embeddingModelId);

    if (countryModelGroup) {
      const apiModel = getModelsForCountry(
        countryModelGroup.country,
        countryModelGroup.language
      )[0];

      try {
        const response = await AiWriterAPI.create({
          audience_id: audienceId,
          name,
          keywords,
          brand: keywords2,
          text: aiWriterTextToApi(text || defaultText),
          short_text: getProjectShortText(text || defaultText),
          config_text: brief,
          embedding_model_id: apiModel.id,
          output_type: outputType,
          tonality,
          sharing_permission: SharingPermission.private,
          personality_id: personalityId
        });

        if (!response.status) {
          const errorId = handleApiCommonErrors(response.data.message);

          Toast.backendError(errorId);
          dispatch(initializeTab.failure());
          return;
        }

        const project = aiWriterProjectFromApi(response.data);
        const tab = aiWriterTabFromProject(project);

        GAEvents.createdNewDocument({
          documentId: response.data.id,
          customerId: state.customer.id
        });
        trackingWrapper.track('aiWriterNewDocument', {
          documentId: response.data.id,
          customerId: state.customer.id
        });

        if (config.shouldCollapseSidebar) {
          collapseSidebar();
        }

        dispatch(initializeTab.success({ ...tab, isNewDocument }));

        if (tabConfiguration) {
          dispatch(tabConfiguration(tab));
        }
      } catch (e) {
        Toast.apiError();
      }
    }
  };
