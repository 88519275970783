import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import styled, { DefaultTheme } from 'styled-components';

export const BANNER_BREAKING_POINT = 1250;
export const BANNER_HIDE_BREAKING_POINT = 550;

type BannerProps = {
  title: ReactElement;
  content: ReactElement;
  image: string;
  color: keyof DefaultTheme['colors']['palette'];
  children?: ReactElement;
};

export const HomeBannerLayout = (props: BannerProps) => {
  return (
    <Root $color={props.color}>
      <Body>
        <HeaderText>{props.title}</HeaderText>
        <BodyText>{props.content}</BodyText>
        <RightContainer>{props.children}</RightContainer>
      </Body>
      <ImageContainer>
        <Image src={props.image} />
      </ImageContainer>
    </Root>
  );
};

const Root = styled.div<{ $color: keyof DefaultTheme['colors']['palette'] }>`
  max-height: 165px;
  border-radius: ${({ theme }) => theme.borderRadius.one};
  overflow: hidden;
  background-color: ${({ theme, $color }) => theme.colors.palette[$color]};

  display: flex;
  align-items: center;

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: ${({ theme }) => theme.spacings.one};
  }

  @media (max-width: ${BANNER_HIDE_BREAKING_POINT}px) {
    display: none;
  }
`;

const Body = styled.div`
  padding: ${({ theme }) => theme.spacings.six};

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.one};

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: ${({ theme }) => theme.spacings.one};
  }
`;

const HeaderText = styled(Typography).attrs({
  variant: 'h5',
  color: 'white'
})`
  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    ${({ theme }) => ({
      ...theme.typography.h5
    })}
  }
`;

const BodyText = styled(Typography).attrs({
  variant: 'body1',
  color: 'white'
})`
  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    ${({ theme }) => ({
      ...theme.typography.body2
    })}
  }
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 60%;
  height: 100%;

  @media (max-width: 660px) {
    display: none;
  }
`;

const Image = styled.img`
  width: 110%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateX(-5%);
  max-width: unset;
`;
