import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import SeoStatisticsSection from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/common/SeoStatisticsSection';
import SeoRefreshTermSuggestionsButton from 'features/aiWriter/AiWriterSidebar/steps/seoAnalysis/SeoAnalysisMainSubStep/SeoRefreshTermSuggestionsButton';
import { setInspirationsCurrentStep } from 'features/aiWriter/store/actions/config/actions';
import { getSeoAnalysisStatisticsSummary, getSeoConfig } from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { getIsSeoLimitReached } from 'features/pricing/store/selectors';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

const MainSubStepStatisticsSection = () => {
  const dispatch = useAppDispatch();
  const tr = useTr();

  const seoAnalysisStatistics = useAppSelector(getSeoAnalysisStatisticsSummary);
  const seoConfig = useAppSelector(getSeoConfig);
  const seoLimitReached = useAppSelector(getIsSeoLimitReached);

  if (!seoAnalysisStatistics) {
    return null;
  }

  const showRefreshButton = seoConfig && !seoLimitReached;

  return (
    <FlexContainer gap="medium">
      <FlexContainer gap="xxsmall">
        <FlexContainer direction="row" justifyContent="space-between">
          <Typography variant="h6">
            <FormattedMessage id="aiWriter.inspirations.seoAnalysis.statistics.title" />
          </Typography>
          {showRefreshButton && (
            <SeoRefreshTermSuggestionsButton
              keyword={seoConfig.keyword}
              searchEngine={seoConfig.searchEngine}
            />
          )}
        </FlexContainer>
        <Typography variant="caption">
          <FormattedMessage
            id="aiWriter.inspirations.seoAnalysis.statistics.subtitle"
            values={{
              a: (text: string) => (
                <a
                  href={tr('aiWriter.inspirations.seoAnalysis.statistics.subtitle.link')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text}
                </a>
              )
            }}
          />
        </Typography>
      </FlexContainer>
      <SeoStatisticsSection
        more={seoAnalysisStatistics.more}
        less={seoAnalysisStatistics.less}
        perfect={seoAnalysisStatistics.perfect}
      />
      <Button
        id={gtmIds.aiWriter.seo.analysisTFIDF}
        endIcon={<ArrowForwardIcon color="primary" />}
        onClick={() =>
          dispatch(
            setInspirationsCurrentStep({
              step: AiWriterSidebarStep.performance,
              subStep: 'seoStatistics'
            })
          )
        }
      >
        <FormattedMessage id="aiWriter.inspirations.seoAnalysis.full_analysis" />
      </Button>
    </FlexContainer>
  );
};

export default MainSubStepStatisticsSection;
