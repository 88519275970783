import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { FormattedMessage } from 'react-intl';

const renderTipMessage = (props: { tipId: IntlMessageKeys }) => (
  <FormattedMessage
    id={props.tipId}
    values={{
      b: (chunks: string) => <b>{chunks}</b>
    }}
  />
);

const commonTips = [
  renderTipMessage({ tipId: 'tip_loader.tips.common.1' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.2' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.3' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.4' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.5' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.6' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.7' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.8' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.9' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.10' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.11' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.12' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.13' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.14' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.15' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.16' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.17' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.18' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.19' }),
  renderTipMessage({ tipId: 'tip_loader.tips.common.20' })
];

export const imageGeneratorTips = [
  ...commonTips,
  renderTipMessage({ tipId: 'tip_loader.tips.image_generation.1' }),
  renderTipMessage({ tipId: 'tip_loader.tips.image_generation.2' }),
  renderTipMessage({ tipId: 'tip_loader.tips.image_generation.3' }),
  renderTipMessage({ tipId: 'tip_loader.tips.image_generation.4' })
];

export const textGeneratorTips = [
  renderTipMessage({ tipId: 'tip_loader.tips.text_generation.1' }),
  ...commonTips
];

export const personalityTips = [
  renderTipMessage({ tipId: 'tip_loader.tips.personality_generation.1' }),
  renderTipMessage({ tipId: 'tip_loader.tips.personality_generation.2' }),
  renderTipMessage({ tipId: 'tip_loader.tips.personality_generation.3' }),
  renderTipMessage({ tipId: 'tip_loader.tips.personality_generation.4' }),
  renderTipMessage({ tipId: 'tip_loader.tips.personality_generation.5' }),
  renderTipMessage({ tipId: 'tip_loader.tips.personality_generation.6' }),
  renderTipMessage({ tipId: 'tip_loader.tips.personality_generation.7' }),
  renderTipMessage({ tipId: 'tip_loader.tips.personality_generation.8' }),
  renderTipMessage({ tipId: 'tip_loader.tips.personality_generation.9' }),
  renderTipMessage({ tipId: 'tip_loader.tips.personality_generation.10' })
];
