import AuthRoute from 'components/auth/AuthRoute';
import { getRoutePath, RouteName, routesConfig, shouldPageBeVisible } from 'config/routes';
import { AiImagesPage } from 'features/aiImages/AiImagesPage';
import { AiImagesPreviewPage } from 'features/aiImages/AiImagesPreviewPage/AiImagesPreviewPage';
import { NewDocumentPage } from 'features/aiWriter/NewDocumentPage';
import { CustomerRole, Team } from 'features/customer/store/types';
import { OnboardingSurvey } from 'features/onboardingJourney/OnboardingSurvey';
import { OnboardingUsecase } from 'features/onboardingJourney/OnboardingUsecase';
import { OnboardingWelcome } from 'features/onboardingJourney/OnboardingWelcome';
import { UsersCard } from 'features/profilePage/UsersCard';
import { VerifyAccount } from 'features/VerifyAccount/VerifyAccount';
import { BlogPostBuilderPage } from 'features/workflowBlogPostBuilder/BlogPostBuilderPage';
import { SocialPostBuilderPage } from 'features/workflowSocialPostBuilder/SocialPostBuilderPage';
import ActivationMailPage from 'pages/activationMail';
import AiWriterPage from 'pages/aiWriter';
import { AiWriterPreviewPage } from 'pages/aiWriterPreview';
import { BonusReferralPage } from 'pages/bonusReferral';
import { BrandHubOverviewPage } from 'pages/brand-hub/BrandHubOverviewPage';
import { ModulareWorkflowCreatePage } from 'pages/brand-hub/modular-workflow/create';
import { ModulareWorkflowEditPage } from 'pages/brand-hub/modular-workflow/edit';
import { ModulareWorkflowRunPage } from 'pages/brand-hub/modular-workflow/run';
import { CampaignPage } from 'pages/CampaignPage';
import DimensionsManagementPage from 'pages/dimensionsManagement';
import { ExplorerPage } from 'pages/exploring/ExplorerPage';
import { ForgotPasswordPage } from 'pages/forgotPassword/ForgotPasswordPage';
import { FreeTrialPage } from 'pages/FreeTrialPage';
import HomePage from 'pages/home';
import { LoginPage } from 'pages/login/LoginPage';
import { MyContentPage } from 'pages/myContent/MyContentPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import PaymentSuccessPage from 'pages/paymentSuccess';
import PerformanceFlashInfoPage from 'pages/performanceFlashInfo';
import ProfilePage from 'pages/profile';
import { RegisterPage } from 'pages/register/RegisterPage';
import { RegisterSuccessPage } from 'pages/registerSuccess/RegisterSuccessPage';
import ResearchFlashInfoPage from 'pages/researchFlashInfo';
import { ResendMail } from 'pages/resendMail/ResendMail';
import { ResetPassword } from 'pages/resetPassword/ResetPassword';
import { ResetPasswordInvalid } from 'pages/resetPassword/ResetPasswordInvalid';
import SocialLoginCallbackPage from 'pages/social-login';
import { SocialRegisterPage } from 'pages/socialRegister/SocialRegisterPage';
import SubjectLinesPage from 'pages/subjectLines';
import { TeamInvitationExistingCustomerLandingpage } from 'pages/teamInvitationExistingCustomerLandingpage';
import { TeamInvitationNewCustomerLandingpage } from 'pages/teamInvitationNewCustomerLandingpage';
import AiTesterPage from 'pages/testing';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';

const routerRoutes: Record<RouteName, React.ComponentType<unknown>> = {
  activationMail: ActivationMailPage,
  aiWriter: AiWriterPage,
  aiWriterPreview: AiWriterPreviewPage,
  aiImagesPreview: AiImagesPreviewPage,
  aiTester: AiTesterPage,
  dimensionsManagement: DimensionsManagementPage,
  exploring: ExplorerPage,
  forgotPassword: ForgotPasswordPage,
  free: FreeTrialPage,
  home: HomePage,
  login: LoginPage,
  profileInfo: ProfilePage,
  profileDashboard: ProfilePage,
  profileBilling: ProfilePage,
  workspace: UsersCard,
  register: RegisterPage,
  registerSuccess: RegisterSuccessPage,
  resendMail: ResendMail,
  resetPassword: ResetPassword,
  resetPasswordInvalid: ResetPasswordInvalid,
  subjectLines: SubjectLinesPage,
  socialLoginCallback: SocialLoginCallbackPage,
  aiImages: AiImagesPage,
  paymentSuccess: PaymentSuccessPage,
  bonusReferral: BonusReferralPage,
  verifyAccount: VerifyAccount,
  teamInvitationNewCustomerLandingpage: TeamInvitationNewCustomerLandingpage,
  teamInvitationExistingCustomerLandingpage: TeamInvitationExistingCustomerLandingpage,
  onboardingWelcome: OnboardingWelcome,
  onboardingSurvey: OnboardingSurvey,
  onboardingUsecase: OnboardingUsecase,
  socialRegister: SocialRegisterPage,
  productInfoTesting: PerformanceFlashInfoPage,
  productInfoExploring: ResearchFlashInfoPage,
  blogPostWorkflow: BlogPostBuilderPage,
  socialPostWorkflow: SocialPostBuilderPage,
  newDocument: NewDocumentPage,
  modularWorkflowCreateNew: ModulareWorkflowCreatePage,
  modularWorkflowEdit: ModulareWorkflowEditPage,
  modularWorkflowRun: ModulareWorkflowRunPage,
  brandHubOverviewWorkflows: BrandHubOverviewPage,
  brandHubOverviewBrandVoice: BrandHubOverviewPage,
  brandHubOverviewInformation: BrandHubOverviewPage,
  brandHubOverviewTemplates: BrandHubOverviewPage,
  campaign: CampaignPage,
  pageNotFound: NotFoundPage,
  myContent: MyContentPage
};

const redirects = {
  '/bubble-graph': getRoutePath('exploring'),
  '/concept-flash': getRoutePath('exploring'),
  '/content-flash': getRoutePath('aiTester')
};

export const getRoutes = (
  userGroupId: number | null,
  userRole: CustomerRole,
  isApiCustomer: boolean,
  customerTeams: Team[]
) =>
  [<Route path="/" key="/" element={<LoginPage />} />]
    .concat(
      Object.entries(routesConfig)
        .filter(([, config]) =>
          shouldPageBeVisible(config, userGroupId, userRole, isApiCustomer, customerTeams)
        )
        .map(([route, config]) => {
          const RouteComponent = routerRoutes[route as RouteName];
          const routeElement = config.auth ? (
            <AuthRoute component={RouteComponent} routeName={route as RouteName} />
          ) : (
            <RouteComponent />
          );

          return <Route path={config.path} key={config.path} element={routeElement} />;
        })
    )
    .concat(
      Object.entries(redirects)
        .map(([from, to]) => (
          <Route key={`${from}_${to}`} path={from} element={<Navigate to={to} />} />
        ))
        .concat(<Route key="*" path="*" element={<NotFoundPage />} />)
    );
