export function makeBackofficeUrl(relativePath: string) {
  let baseUrl = getBackofficeBaseUrl();
  // Ensure that the base url ends with a slash
  if (!baseUrl.endsWith('/')) {
    baseUrl += '/';
  }

  // If both paths have a slash in between, remove the slash from the relative path
  if (relativePath.startsWith('/')) {
    relativePath = relativePath.slice(1);
  }

  return baseUrl + relativePath;
}

export function getBackofficeBaseUrl() {
  return process.env.REACT_APP_API;
}
