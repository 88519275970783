import { AxiosError, AxiosResponse } from 'axios';
import { logError } from 'features/logging/logError';
import { transactionIdHeaderName } from 'features/logging/transactionIdHeaderName';
import { isObject } from 'utils/isObject';

const responseStatusValue = (response: AxiosResponse | undefined) => {
  const status = response?.status;
  return status ? String(status) : 'no-response';
};

/**
 * Tries to get the x-trans-id from the axios request configuration,
 * which is added to the error thrown by axios
 */
export const tryGetTransactionIdFromAxiosError = (error: AxiosError) => {
  if (isObject(error.config?.headers)) {
    const transactionIdHeader = error.config?.headers[transactionIdHeaderName];
    if (typeof transactionIdHeader === 'string' && transactionIdHeader.length > 0) {
      return transactionIdHeader;
    }
  }

  return undefined;
};

export const logAxiosError = (error: AxiosError) => {
  const url = error.config?.url;
  const method = error.config?.method;
  const responseStatus = responseStatusValue(error.response);

  return logError(error, 'http', {
    'meta.http.url': url,
    'meta.http.method': method,
    'meta.http.status': responseStatus,
    'meta.http.transaction_id': tryGetTransactionIdFromAxiosError(error)
  });
};
