import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import { clearPreferredLocaleQuery } from 'features/customerPreferences/usePreferredLocaleQuery';
import { useUpdateCustomerPreferences } from 'features/customerPreferences/useUpdateCustomerPreferences';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { MobileOnboardingFooter } from 'features/onboardingJourney/MobileFooter';
import { NewUserSurveyQuestion } from 'features/onboardingJourney/newUserSurvey/NewUserSurveyQuestion';
import { useOnboardingContext } from 'features/onboardingJourney/newUserSurvey/OnboardingContextProvider';
import { useUpdateSurveyAnswers } from 'features/onboardingJourney/newUserSurvey/useUpdateSurveyAnswers';
import {
  Footer,
  NextButton,
  onboardingContentWidth,
  onboardingMobileThreshold,
  OnboardingRoot
} from 'features/onboardingJourney/OnboardingRoot';
import { useIsDesktop } from 'features/onboardingJourney/useIsDesktop';
import { useDisableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export function OnboardingUsecase() {
  const navigate = useNavigate();
  const tr = useTr();

  useDisableSidebarOnMount();

  const { questions, newAnswers, existingAnswers, setNewAnswers, refetchAnswers } =
    useOnboardingContext();

  const { mutateAsync: updateAnswers } = useUpdateSurveyAnswers(newAnswers);

  const isDesktop = useIsDesktop();

  const selectableQuestions = questions.filter(question => question.slug === 'content_type');

  const hasAnsweredCurrentQuestions = !selectableQuestions.some(
    question => !newAnswers[question.id] && !existingAnswers[question.slug]
  );

  const { mutateAsync: updateCustomerPreferences } = useUpdateCustomerPreferences();

  // This is needed to be done like this to make sure that we don’t run into a timing issue with navigating
  // a user back to onboarding too quickly (happens in useNavigateToOnboarding hook) after the preferences
  // update in onboarding-selection step.
  useEffect(() => {
    updateCustomerPreferences({ newUserSurveySeen: true });
  }, [updateCustomerPreferences]);

  const handleNextClick = async () => {
    // Store answers, if any given
    if (Object.entries(newAnswers).length > 0) {
      await updateAnswers();
      // User survey may affect preferred locale so we have to clear the
      // query cache to enforce fresh data. We could probably optimize and
      // check if language was actually changed, but not sure if this is worth it.
      clearPreferredLocaleQuery();

      refetchAnswers();
    }

    navigate(getRoutePath('home'));
  };

  return (
    <OnboardingRoot title={tr('aiWriter.onboarding_journey.usecase.header')}>
      <QnAContainer>
        {selectableQuestions.map(currentQuestion => {
          return (
            <QnAPair key={currentQuestion.id}>
              <Answer key={Math.random()}>
                <NewUserSurveyQuestion
                  question={currentQuestion}
                  onChange={option =>
                    setNewAnswers(prevState => ({
                      ...prevState,
                      [currentQuestion.id]: option.id
                    }))
                  }
                />
              </Answer>
            </QnAPair>
          );
        })}
      </QnAContainer>

      {isDesktop && (
        <Footer direction="row" alignItems="center" gap="small">
          <NextButton
            {...withGtmInteraction(gtmIds.onboardingJourney.survey.next)}
            color="primary"
            variant="contained"
            onClick={handleNextClick}
            disabled={!hasAnsweredCurrentQuestions}
            fullWidth={true}
          >
            <FormattedMessage id="survey.new_user.next" />
          </NextButton>
        </Footer>
      )}

      {!isDesktop && (
        <MobileOnboardingFooter
          buttons={[
            <NextButton
              {...withGtmInteraction(gtmIds.onboardingJourney.survey.next)}
              color="primary"
              variant="contained"
              onClick={handleNextClick}
              disabled={!hasAnsweredCurrentQuestions}
              fullWidth={true}
              key="nextButton"
            >
              <FormattedMessage id="survey.new_user.next" />
            </NextButton>
          ]}
        />
      )}
    </OnboardingRoot>
  );
}

const QnAContainer = styled.div`
  width: ${onboardingContentWidth};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.four};

  @media (max-width: ${onboardingMobileThreshold}) {
    max-width: 100%;
    gap: ${({ theme }) => theme.spacings.three};
  }
`;

const QnAPair = styled(FlexContainer)`
  gap: ${({ theme }) => theme.spacings.two};
`;

const Answer = styled.div`
  justify-self: end;
  height: 100%;
`;
