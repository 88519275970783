import { backofficeClientStore } from 'services/backofficeIntegration/http/client/backofficeClientStore';

export type ErrorScope = 'unknown' | 'http' | 'react' | 'component' | 'saga';

export const logError = (
  errorInfo: Error | string,
  errorScope: ErrorScope | undefined = 'unknown',
  additionalData?: Record<string, unknown>
) => {
  // eslint-disable-next-line no-console
  console.error(errorInfo, additionalData);

  const error = typeof errorInfo === 'string' ? new Error(errorInfo) : errorInfo;

  return backofficeClientStore.getState().client?.post(
    '/logs',
    btoa(
      JSON.stringify({
        message: error.message,
        severity: 'ERROR',
        stack_trace: error.stack,
        context: {
          'meta.timestamp': new Date().toISOString(),
          'meta.scope': errorScope,
          ...additionalData
        }
      })
    )
  );
};

// @ts-expect-error - Expose logError to the window object for debugging purposes
window.logError = logError;
