import { AiWriterSidebar } from 'features/aiWriter/AiWriterSidebar/AiWriterSidebar';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { useGetPrompts } from 'features/textGenerator/utils/useGetPrompts';
import { CommonWorkflowContainer } from 'features/workflow/CommonWorkflowContainer';
import { SelectOutlineStep, SelectTitleStep, Step2 } from 'features/workflowBlogPostBuilder/steps';
import { GenerateContentStep } from 'features/workflowBlogPostBuilder/steps/GenerateContentStep';
import { Step1 } from 'features/workflowBlogPostBuilder/steps/Step1';
import { Step3 } from 'features/workflowBlogPostBuilder/steps/Step3';
import { BlogBuilderSteps } from 'features/workflowBlogPostBuilder/types';
import { useAutoTriggerAfterTimeout } from 'features/workflowBlogPostBuilder/useAutoTriggerAfterTimeout';
import { useSteps } from 'features/workflowBlogPostBuilder/useSteps';
import { useSyncWithInspirationPanel } from 'features/workflowBlogPostBuilder/useSyncWithInspirationPanel';
import { useCallback, useMemo } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';

const redirectAfterTextGenerationDelay = 4000;

export const BlogPostBuilder = () => {
  const { currentStep, isNextStepBtnDisabled, onPreviousStep, onPrimaryAction, goToStep } =
    useSteps();

  const textGenerationFinished =
    currentStep === BlogBuilderSteps.GenerateText && !isNextStepBtnDisabled;

  useSyncWithInspirationPanel();

  useAutoTriggerAfterTimeout({
    shouldTrigger: textGenerationFinished,
    delay: redirectAfterTextGenerationDelay,
    action: onPrimaryAction
  });

  // Make sure prompts are loaded
  useGetPrompts();

  const handleGoToKeywords = useCallback(() => {
    goToStep(BlogBuilderSteps.Step1);
  }, [goToStep]);

  const renderedStep = useMemo(() => {
    switch (currentStep) {
      case BlogBuilderSteps.Step1:
        return <Step1 />;
      case BlogBuilderSteps.Step2:
        return <Step2 />;
      case BlogBuilderSteps.Step3:
        return <Step3 onGoToKeywordsClick={handleGoToKeywords} />;

      case BlogBuilderSteps.Step4:
        return <SelectTitleStep />;
      case BlogBuilderSteps.Step5:
        return <SelectOutlineStep />;
      case BlogBuilderSteps.GenerateText:
        return <GenerateContentStep isFinished={!isNextStepBtnDisabled} />;
    }
  }, [currentStep, handleGoToKeywords, isNextStepBtnDisabled]);

  return (
    <>
      <CommonWorkflowContainer
        stepCount={6}
        activeStep={currentStep}
        nextButtonProps={{
          ...withGtmInteraction(gtmIds.blogPostBuilder.primaryAction, {
            withDeprecatedStyle: true
          }),
          disabled: isNextStepBtnDisabled,
          onClick: onPrimaryAction
        }}
        hasBackButton={currentStep !== BlogBuilderSteps.Step1}
        backButtonProps={{
          onClick: onPreviousStep
        }}
      >
        {renderedStep}
      </CommonWorkflowContainer>

      <AiWriterSidebar isDisabled={true} availableSteps={[AiWriterSidebarStep.aiWriter]} />
    </>
  );
};

export default BlogPostBuilder;
