import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { ArrowForwardRounded } from '@mui/icons-material';
import { Button, Link, Typography } from '@mui/material';
import backgroundBanner from 'assets/home/background-banner.png';
import doodle from 'assets/home-doodle.svg';
import { ReactComponent as CrownIcon } from 'assets/icons/crown.svg';
import { Container } from 'components/Container';
import FlexContainer from 'components/FlexContainer';
import { getRoutePath } from 'config/routes';
import { CreateChatForm } from 'features/aiWriter/AiWriterProjectOverview/chatForm/CreateChatForm';
import { CreateWorkflowButton } from 'features/aiWriter/AiWriterProjectOverview/CreateWorkflowButton';
import { getIsApiCustomer } from 'features/customer/store/selectors';
import { ApiDashboard } from 'features/homePage/ApiDashboard';
import { CarouselComponent } from 'features/homePage/Carousel';
import { HomeBannerLayout } from 'features/homePage/HomeBannerLayout';
import { HomeFeatureCards } from 'features/homePage/HomeFeatureCards';
import { HomeRecentFiles } from 'features/homePage/HomeRecentFiles';
import { QuickActionCardProps, useQuickActions } from 'features/homePage/useQuickActions';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { logInfo } from 'features/logging/logInfo';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { useShowPersonalityCreationModal } from 'features/personality/creation/PersonalityCreationModal';
import { ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

const HomePage = (): ReactElement => {
  const navigate = useNavigate();
  const isApiCustomer = useAppSelector(getIsApiCustomer);

  const quickActions = useQuickActions();
  const [firstSlide, secondSlide] = quickActions;

  useEnableSidebarOnMount();

  useNavigateToOnboarding();

  logInfo('Logging works');

  const handleViewAllClick = () => {
    navigate(getRoutePath('myContent'));
  };

  if (isApiCustomer) {
    return <ApiDashboard />;
  }

  return (
    <Container size="lg">
      <Doodle src={doodle} />
      <ContentContainer gap="four">
        <Title />
        <CreateChatForm />
        <FlexContainer gap="two">
          <SectionHeadline>
            <FormattedMessage id="home.sections.tools.title" />
          </SectionHeadline>
          <CarouselComponent>
            <WorkflowButtons>
              {firstSlide.map(
                ({ name, title, subtitle, gtmId, onClick, icon }: QuickActionCardProps) => (
                  <CreateWorkflowButton
                    key={name}
                    title={title}
                    subtitle={subtitle}
                    gtmId={gtmId}
                    onClick={onClick}
                    icon={icon}
                  />
                )
              )}
            </WorkflowButtons>
            <WorkflowButtons>
              {secondSlide.map(
                ({ name, title, subtitle, gtmId, onClick, icon }: QuickActionCardProps) => (
                  <CreateWorkflowButton
                    key={name}
                    title={title}
                    subtitle={subtitle}
                    gtmId={gtmId}
                    onClick={onClick}
                    icon={icon}
                  />
                )
              )}
            </WorkflowButtons>
          </CarouselComponent>
        </FlexContainer>
        <HomeBanner />
        <FlexContainer gap="two">
          <FlexContainer direction="row" justifyContent="space-between">
            <SectionHeadline>
              <FormattedMessage id="home.sections.recentFiles.title" />
            </SectionHeadline>
            <ViewAllButton onClick={handleViewAllClick}>
              <FormattedMessage id="home.sections.recentFiles.button.viewAll" />
            </ViewAllButton>
          </FlexContainer>

          <HomeRecentFiles />
        </FlexContainer>
        <FlexContainer gap="two">
          <FlexContainer direction="row" gap="two" alignItems="baseline">
            <SectionHeadline>
              <FormattedMessage id="home.sections.premiumTools.title" />
            </SectionHeadline>
            <CrownImage />
          </FlexContainer>
          <HomeFeatureCards />
        </FlexContainer>
      </ContentContainer>
    </Container>
  );
};

function Title() {
  return (
    <Root>
      <Typography variant="h4">
        <FormattedMessage id="home.title" />
      </Typography>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  justify-content: center;
`;

function SectionHeadline({ children }: { children: ReactNode }) {
  return <Typography variant="subtitle1">{children}</Typography>;
}

const HomeBanner = () => {
  const showPersonalityCreationModal = useShowPersonalityCreationModal();
  const handleCreateBrandVoiceClick = () => {
    showPersonalityCreationModal({
      initialViewMode: 'choice',
      isLanguageEditable: true
    });
  };

  return (
    <HomeBannerLayout
      title={<FormattedMessage id="home.banner.brandVoice.title" />}
      content={<FormattedMessage id="home.banner.brandVoice.description" />}
      image={backgroundBanner}
      color="blue__500main"
    >
      <CreateBrandVoiceButton
        variant="contained"
        size="small"
        startIcon={<ArrowForwardRounded />}
        onClick={handleCreateBrandVoiceClick}
      >
        <FormattedMessage id="home.banner.brandVoice.button.create" />
      </CreateBrandVoiceButton>
    </HomeBannerLayout>
  );
};

const ViewAllButton = styled(Link)`
  color: ${({ theme }) => theme.colors.commonBlackMain};
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  text-decoration-line: underline;
  :visited,
  :hover {
    color: ${({ theme }) => theme.colors.commonBlackMain};
  }
  cursor: pointer;
`;

const CrownImage = styled(CrownIcon)`
  path {
    fill: ${({ theme }) => theme.colors.accentYellowMain};
    fill-opacity: 1;
  }
`;

const WorkflowButtons = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.three};
  grid-template-columns: repeat(3, 1fr);
`;

const ContentContainer = styled(FlexContainer)`
  margin-bottom: ${({ theme }) => theme.spacings.xlarge};
`;

const CreateBrandVoiceButton = styled(Button)`
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.commonWhiteMain};
  color: ${({ theme }) => theme.colors.primaryColorMain};
  :hover {
    background-color: ${({ theme }) => theme.colors.commonWhiteMain};
  }
`;

const Doodle = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;

export default HomePage;
