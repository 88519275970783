import { Button } from '@mui/material';
import AutocompleteChipsInput from 'components/AutocompleteInput/AutocompleteChipsInput';
import FlexContainer from 'components/FlexContainer';
import AutoSaveFormik from 'components/forms/AutoSaveFormik';
import { useModal } from 'components/modals';
import LocalStorageKey from 'config/localStorageKey';
import { getEmbeddingModelMapperVariablesSelector } from 'features/embeddingModels/store/selectors';
import { InputAttachedFilterMenu } from 'features/explorer/InputAttachedFilterMenu';
import { getCurrentTabModelData, getIsTabInitialized } from 'features/explorer/store/selectors';
import { setTabModel } from 'features/explorer/store/slice';
import { ConceptFlashGoal, GoalValues, ModelValues } from 'features/explorer/store/types';
import { prepareGoalConfig } from 'features/explorer/store/utils';
import { TabInitialization } from 'features/explorer/TabInitialization';
import { Form, FormikProps } from 'formik';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export type ConfigFormValues = ConceptFlashGoal & {
  seedWords: string[];
  listId: number | undefined;
};

const ConfigForm = ({
  values,
  submitForm,
  setFieldValue,
  setValues
}: FormikProps<ConfigFormValues>) => {
  const { showModal } = useModal();

  const dispatch = useAppDispatch();
  const isInitialized = useAppSelector(getIsTabInitialized);
  const model = useAppSelector(getCurrentTabModelData);
  const getMapperVariables = useAppSelector(getEmbeddingModelMapperVariablesSelector);

  const { language: modelLanguage } = model || {};

  const openConfigModal = () => {
    const { wordAttributes, seoParams, dimensions, manualDimensions } = values;
    showModal({
      modalType: 'EXPLORING_GOAL_MODAL',
      modalProps: {
        size: 1100,
        onSubmit: (modalValues: GoalValues) => {
          const { dimensions, manualDimensions, wordAttributes } = modalValues;
          setValues({
            ...values,
            ...modalValues,
            wordAttributes,
            goalConfig: prepareGoalConfig(wordAttributes, dimensions, manualDimensions)
          });
        },
        initialValues: {
          wordAttributes,
          seoParams,
          dimensions,
          manualDimensions
        }
      }
    });
  };

  const onSetGoal = useCallback(
    ({ wordAttributes, dimensions, manualDimensions, seoParams }: GoalValues) => {
      setFieldValue('wordAttributes', wordAttributes);
      setFieldValue('dimensions', dimensions);
      setFieldValue('manualDimensions', manualDimensions);
      setFieldValue('seoParams', seoParams);
      setFieldValue('goalConfig', prepareGoalConfig(wordAttributes, dimensions, manualDimensions));
    },
    [setFieldValue]
  );

  const onSetModel = useCallback(
    ({ model: { modelId, modelMapper } }: ModelValues) => {
      const mapperVariables = getMapperVariables(modelId, modelMapper);
      dispatch(
        setTabModel({
          embeddingModelId: modelId,
          modelMapperId: modelMapper,
          hasValence: mapperVariables.includes('valence')
        })
      );
      localStorage.setItem(LocalStorageKey.ExplorerModel, modelId);
      localStorage.setItem(LocalStorageKey.ExplorerModelMapper, modelMapper);
    },
    [getMapperVariables, dispatch]
  );

  if (!isInitialized) {
    return (
      <Form>
        <TabInitialization
          seedWords={values.seedWords}
          onChange={newSeedWords => {
            setFieldValue('seedWords', newSeedWords);
          }}
          submitForm={submitForm}
          onSetModel={onSetModel}
          onSetGoal={onSetGoal}
        />
      </Form>
    );
  }

  return (
    <Form>
      <FormGrid>
        {!values.listId && (
          <>
            <InputLabelContainer>
              <FormattedMessage id="exploring.form.title" />
            </InputLabelContainer>
            <FlexContainer direction="row">
              <StyledAutocompleteChipsInput
                onChange={newSeedWords => {
                  setFieldValue('seedWords', newSeedWords);
                }}
                model={model?.id}
                initialValue={values.seedWords}
                textFieldProps={{
                  variant: 'outlined',
                  fullWidth: true,
                  label:
                    values.seedWords.length === 0 ? (
                      <FormattedMessage id="common.autocomplete.tags_input.placeholder" />
                    ) : (
                      ''
                    ),
                  placeholder: ''
                }}
              />

              <InputAttachedFilterMenu
                name="filters"
                selectedFilters={values.filters.length}
                modelLanguage={modelLanguage}
                clearFilters={() => {
                  setFieldValue('filters', []);
                  submitForm();
                }}
              />
            </FlexContainer>
          </>
        )}

        <ActionsContainer>
          <Button color="primary" onClick={openConfigModal}>
            <FormattedMessage id="goal_score.goals" />
          </Button>
        </ActionsContainer>
      </FormGrid>
      <AutoSaveFormik waitTime={500} />
    </Form>
  );
};

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: fit-content(14rem) 730px;
  column-gap: 1rem;
  row-gap: 0.25rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, 100%);
  }
`;

const ActionsContainer = styled.div`
  grid-column-start: 2;
  display: flex;
  justify-content: flex-start;
`;

const StyledAutocompleteChipsInput = styled(AutocompleteChipsInput)`
  width: 100%;
  max-width: 40rem;
`;

const InputLabelContainer = styled.div`
  display: flex;
  align-items: center;

  width: fit-content;
  white-space: nowrap;
`;

export default ConfigForm;
