import CloseIcon from '@mui/icons-material/Close';
import { IconButton, LinearProgress, Paper, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { withTestId } from 'utils/utils';
export function Checklist(
  props: PropsWithChildren<{
    title: ReactNode;
    bodyText: ReactNode;
    progress: number;
    children: ReactNode;
    onClose?: () => void;
  }>
   
) {
  const { title, bodyText, progress, children, onClose } = props;
  return (
    <Root {...withTestId('checklist-root')}>
      <Header>
        <Title variant="h6" {...withTestId('checklist-title')}> {title} </Title>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Header>

      <Typography variant="body1">{bodyText}</Typography>

      {children}

      <Footer>
        <ProgressBar variant="determinate" value={progress} />
        <Typography>{progress}%</Typography>
      </Footer>
    </Root>
  );
}
const Root = styled(Paper).attrs({
  elevation: 6
})`
  margin-bottom: ${({ theme }) => theme.spacings.three};
  padding: ${({ theme }) => theme.spacings.four};
  width: 400px;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.three};
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TaskList = styled.ul`
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.one};
  margin: 0;
`;
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.three};
`;
const ProgressBar = styled(LinearProgress)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondaryColorFocus};

  .MuiLinearProgress-bar1Determinate {
    background-color: ${({ theme }) => theme.colors.secondaryColorMain};
  }
`;
