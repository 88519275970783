import { TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LocalStorageKey from 'config/localStorageKey';
import { UserTonalityInput } from 'features/aiWriter/tonality/UserTonalityInput';
import { ModelAutocomplete } from 'features/embeddingModels/ModelAutocomplete';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { KeywordsHint } from 'features/workflowBlogPostBuilder/steps/components/KeywordsHint';
import { KeywordsLengthExceededHint } from 'features/workflowBlogPostBuilder/steps/components/KeywordsLengthExceededHint';
import { FormValues } from 'features/workflowBlogPostBuilder/types';
import { useField, useFormikContext } from 'formik';
import { upperFirst } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { getWordCount } from 'utils/getWordCount';
import useTr from 'utils/hooks/useTr';

import * as CommonStyled from './common.styles';

const useStyles = makeStyles<Theme>({
  customerTonalityRoot: {
    width: '100%'
  }
});

const maxTextLength = 50;
const maxAmountKeywords = 1;

export const Step1 = () => {
  const classes = useStyles();

  const translate = useTr();
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const postHog = usePostHog();

  const [hasExceededTextLength, setHasExceededTextLength] = useState(false);

  const validateGoal = (value: string) => {
    if (getWordCount(value) > maxTextLength) {
      return translate('blog_post_builder.steps.goal.word_limit_exceeded');
    }
  };

  const { setFieldValue, values, errors } = useFormikContext<FormValues>();
  const [formikGoalField, { error, touched }] = useField({
    name: 'goal',
    type: 'text',
    validate: validateGoal
  });

  const errorMessage = upperFirst(error);

  useEffect(() => {
    if (errors.goal) {
      setFieldValue('disableNextStep', getWordCount(values.goal) > maxTextLength);
    }
  }, [errors.goal, setFieldValue, values.goal]);

  const handleKeywordChange = (newKeywords: string[]) => {
    postHog?.capture('No of selected tone of voice', {
      amount: newKeywords.length,
      tonalities: newKeywords
    });

    if (newKeywords.join('').length > maxTextLength) {
      setHasExceededTextLength(true);
      return;
    }

    setHasExceededTextLength(false);
    setFieldValue('keywords', newKeywords);

    if (newKeywords.length === 0) {
      setFieldValue('elements', []);
    }
  };

  return (
    <>
      <CommonStyled.Title>
        <FormattedMessage id="blog_post_builder.steps.1.title" />
      </CommonStyled.Title>

      <ModelAutocomplete
        name="language"
        models={models}
        onSelect={value => {
          localStorage.setItem(LocalStorageKey.AiWriterModel, value);
        }}
      />

      <TextField
        {...formikGoalField}
        placeholder={translate('blog_post_builder.steps.goal.placeholder')}
        autoComplete="none"
        error={!!error && touched}
        helperText={touched && error ? errorMessage : null}
        fullWidth={true}
      />

      <UserTonalityInput
        maxAmount={maxAmountKeywords}
        userTonalities={values.keywords}
        onChange={handleKeywordChange}
        onInputChange={inputValue => {
          // Disable nex step if user entered a tonality but didn't confirmed it
          setFieldValue('disableNextStep', inputValue.length > 0);
        }}
        classes={{
          root: classes.customerTonalityRoot
        }}
        textFieldProps={{
          placeholder: translate('blog_post_builder.steps.keywords.placeholder')
        }}
      />

      {hasExceededTextLength ? <KeywordsLengthExceededHint /> : <KeywordsHint />}
    </>
  );
};
