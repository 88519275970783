import { Typography } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { LoadingAnimationWithAlternatingMessages } from 'features/loading-spinner/LoadingAnimationWithAlternatingMessages';
import { sample } from 'lodash';
import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  alternatingMessages?: IntlMessageKeys[];
  tips?: ReactNode[];
};

export const LoadingAnimationWithTip = ({ alternatingMessages, tips }: Props) => {
  const tip = sample(tips);

  return (
    <Root>
      <LoadingAnimationWithAlternatingMessages alternatingMessages={alternatingMessages} />
      {tip && (
        <TipContainer>
          <Typography variant="body1" color="text.secondary">
            <FormattedMessage id="tip_loader.did_you_know" />
          </Typography>

          <Typography variant="body1" color="#000000">
            {tip}
          </Typography>
        </TipContainer>
      )}
    </Root>
  );
};

const Root = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.nine};
`;

const TipContainer = styled.div`
  width: auto;
  max-width: 360px;
  // Defined in Figma, but may lead into cut-off text
  //max-height: 154px;
  min-height: 154px;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
  padding: ${({ theme }) => theme.spacings.four};
  border-radius: ${({ theme }) => theme.borderRadius.one};
  border: 1px solid ${({ theme }) => theme.colors.divider};
`;
