import FlexContainer from 'components/FlexContainer';
import { LoadingAnimationWithTip } from 'features/loading-spinner/LoadingAnimationWithTip';
import { SocialPostBuilderStepLayout } from 'features/workflowSocialPostBuilder/SocialPostBuilderStepLayout';
import { useCreateDocument } from 'features/workflowSocialPostBuilder/useCreateDocument';
import { useCreateDocumentStructure } from 'features/workflowSocialPostBuilder/useCreateDocumentStructure';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';

export function Step3Generate() {
  const { mutateAsync: createDocument } = useCreateDocument();
  const { mutateAsync: createDocumentStructure, isLoading } = useCreateDocumentStructure();

  const location = useLocation();
  const hashParams = new URLSearchParams(location.hash.length > 0 ? location.hash.slice(1) : '');
  const skipDocumentCreation = hashParams.has('documentMenu');

  useEffect(() => {
    (async () => {
      if (!skipDocumentCreation) {
        await createDocument();
      }

      await createDocumentStructure();

      GAEvents.reportCustomEvent(gtmIds.socialPostBuilder.generate);
      GAEvents.workflowFinished({
        name: 'social post builder'
      });
    })();
  }, [createDocument, createDocumentStructure, skipDocumentCreation]);

  return (
    <SocialPostBuilderStepLayout
      previousButtonProps={{
        disabled: true
      }}
      nextButtonProps={{
        disabled: isLoading
      }}
    >
      <CenteredContainer>
        <LoadingAnimationWithTip
          alternatingMessages={[
            'blog_post_builder.steps.outline.loader.average_time',
            'blog_post_builder.steps.outline.loader.creativity_progress'
          ]}
        />
      </CenteredContainer>
    </SocialPostBuilderStepLayout>
  );
}

const CenteredContainer = styled(FlexContainer).attrs({
  justifyContent: 'center',
  alignItems: 'center'
})`
  width: 100%;
`;
