import { useQuery } from '@tanstack/react-query';
import { fetchPrompts } from 'features/textGenerator/store/actions/prompts/actions';
import { Prompt } from 'features/textGenerator/store/types';
import { promptFromApi } from 'features/textGenerator/store/utils/mappers/promptMapper';
import { useRef } from 'react';
import { httpGetPrompts } from 'services/backofficeIntegration/http/endpoints/textGeneration/httpGetPrompts';
import { useAppDispatch } from 'store/hooks';

export const useGetPrompts = () => {
  const localPromptsRef = useRef<Prompt[]>();
  const dispatch = useAppDispatch();

  const { data } = useQuery({
    enabled: !localPromptsRef.current,
    queryKey: httpGetPrompts.makeQueryKey(),
    queryFn: () => httpGetPrompts.callEndpoint().then(res => res.map(promptFromApi)),
    onSuccess: data => {
      dispatch(fetchPrompts.success(data));
    },
    staleTime: Infinity
  });

  if (!localPromptsRef.current || localPromptsRef.current.length === 0) {
    localPromptsRef.current = data ?? [];
  }

  return localPromptsRef.current;
};
