import { ReactComponent as BrandHubIcon } from 'assets/sidebar/icon-brand-hub.svg';
import { MenuDropdownItem } from 'components/MenuDropdown/MenuDropdownItem';
import { getRoutePath } from 'config/routes';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { NavLink } from 'features/pageSidebar/sidebarItems/actions/account/UnstyledLink';
import { forwardRef } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import styled from 'styled-components';

type Props = {
  onClose: () => void;
};

export const SidebarWorkflowsItem = forwardRef<HTMLAnchorElement, Props>(({ onClose }, ref) => (
  <NavLink to={getRoutePath('brandHubOverviewWorkflows')} ref={ref}>
    <MenuDropdownItem
      gtmId={gtmIds.sidebar.workflowsItem}
      icon={<BrandHubGreyIcon />}
      onClose={onClose}
    >
      <FormattedMessage id="layout.sidebar.actions.workflows" />
    </MenuDropdownItem>
  </NavLink>
));

const BrandHubGreyIcon = styled(BrandHubIcon)`
  fill: ${({ theme }) => theme.colors.componentsIconActive};
`;
